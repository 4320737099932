import React from "react";
import PropTypes from "prop-types";
import TaskNavigation from "../../_components/TaskNavigation/TaskNavigation";
import AssignmentEdit from "../../";
import BatchFeedbackPanel from "../../../../components/BatchFeedbackPanel";

/**
 * Render AssignmentEdit using an assignment's details
 */
const ByAssignment = (props) => {
  const {
    assignment: { batchId, deliverableId, languageCode, stageId },
    deliverableIds,
    history,
    nextUrl,
    previousUrl,
    projectId,
  } = props;

  const taskNavigation = (
    <TaskNavigation
      currentDeliverableId={deliverableId}
      deliverableIds={deliverableIds}
      history={history}
      nextUrl={nextUrl}
      previousUrl={previousUrl}
    />
  );

  return (
    <div>
      {deliverableId && projectId && stageId && (
        <AssignmentEdit
          {...props}
          {...props.assignment}
          taskNavigationComponent={taskNavigation}
        />
      )}

      {batchId && languageCode && (
        <BatchFeedbackPanel
          batchId={batchId}
          languageCode={languageCode}
          projectId={projectId}
        />
      )}
    </div>
  );
};

ByAssignment.propTypes = {
  assignment: PropTypes.shape({
    batchId: PropTypes.number,
    deliverableId: PropTypes.number,
    languageCode: PropTypes.string,
    stageId: PropTypes.number,
  }),
  deliverableIds: PropTypes.array,
  history: PropTypes.object.isRequired,
  nextUrl: PropTypes.string,
  previousUrl: PropTypes.string,
  projectId: PropTypes.number,
};

export default ByAssignment;
