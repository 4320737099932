import { useCallback, useEffect, useState } from "react";
import { Field, reduxForm } from "redux-form";
import {
  QuillForm,
  QuillFormFieldArray,
} from "../../../../../components/QuillForm";
import { ReduxFormCompatibleSelect as Select } from "../../../../../decorators/ReduxFormCompatible";
import KeywordGroupsArray from "./KeywordGroupsArray";
import styles from "./GlossaryForm.module.scss";

let GlossaryForm = (props) => {
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [termsToDelete, setTermsToDelete] = useState([]);

  useEffect(() => {
    if (props.glossaryLanguages.length > 0) {
      setSelectedLanguages((prevLanguages) => [
        ...new Set([...prevLanguages, ...props.glossaryLanguages]),
      ]);
    }
  }, [props.glossaryLanguages]);

  const handleDropdownChange = useCallback((language) => {
    setSelectedLanguages((prevLanguages) => [...prevLanguages, language]);
  }, []);

  const handleTermsToDelete = (term) => {
    setTermsToDelete([...termsToDelete, term]);
  };

  return (
    <div>
      <QuillForm
        header="Glossary upload"
        cancelText={props.projectCreation ? "Skip" : "Cancel"}
        cancelUrl={`/admin`}
        submitText="Save"
        disabled={props.submitting}
        handleSubmit={props.handleSubmit}
        error={props.error}
        rebrandingContainer
        formName="GlossaryForm"
        uploadForm={(data) => props.uploadGlossary(data, selectedLanguages[0])}
        downloadGlossary={props.downloadGlossary}
        enableReinitialize
        termsToDelete
      >
        <label className={styles.targetLanguage}>
          <p className={styles.header}>
            Add {selectedLanguages?.length === 0 ? "source" : "target"} language
          </p>
          <Field
            clearable={false}
            component={Select}
            name="targetLanguage"
            options={props.languages.filter(
              ({ value }) => !selectedLanguages.includes(value)
            )}
            onChange={handleDropdownChange}
            type="text"
          />
        </label>
        {selectedLanguages.length !== 0 && (
          <QuillFormFieldArray
            name="translations"
            customLabelStyle={{ marginTop: "30px" }}
            selectedLanguages={selectedLanguages}
            component={KeywordGroupsArray}
            type="translation"
            defaultValues={props.translations}
            initialValues={props.initialValues}
            removeTranslation={props.removeTranslation}
            setTermsToDelete={handleTermsToDelete}
          />
        )}
      </QuillForm>
    </div>
  );
};

GlossaryForm = reduxForm({
  form: "GlossaryForm",
})(GlossaryForm);

export default GlossaryForm;
