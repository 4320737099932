import Helmet from "react-helmet";
import PageHeader from "../../../../components/PageHeader";
import Errors from "../../../../components/Errors";
import OverlayLoading from "../../../../components/OverlayLoading/v2";
import GlossaryForm from "./GlossaryForm";

const BulkQAdmin = (props) => {
  const {
    errors,
    parentAccountName,
    accountName,
    initialValues,
    languages,
    glossaryLanguages,
    removeTranslation,
    dataReady,
  } = props;

  return (
    <>
      <Helmet>
        <title>Glossary</title>
      </Helmet>
      {errors && errors.display && <Errors />}

      {!dataReady && <OverlayLoading />}

      <PageHeader
        breadCrumbItems={[
          { url: `/admin`, text: "Accounts" },
          {
            text: `${parentAccountName} | ${accountName}`,
          },
          { text: "View" },
        ]}
        title={"Glossary"}
      ></PageHeader>

      <GlossaryForm
        enableReinitialize
        uploadGlossary={props.uploadGlossary}
        downloadGlossary={props.downloadGlossary}
        initialValues={initialValues}
        onSubmit={props.updateGlossary}
        languages={languages}
        glossaryLanguages={glossaryLanguages}
        removeTranslation={removeTranslation}
      />
    </>
  );
};

export default BulkQAdmin;
