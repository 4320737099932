import { connect } from "react-redux";
import { createSelector } from "reselect";
import ProgressTab from "./component";
import {
  workflowStagesSelector,
  projectLanguagesSelector,
  languageNamesFromLanguages,
  projectLanguagesWithFullNameSelector,
  currentLanguageSelector,
  batchesByProjectId,
} from "../../../utils/entitySelector";
import { selectLanguageSuccess } from "../../../modules/projectLanguages";

const sortData = (flattenedData, sortParams) => {
  if (!sortParams?.sortField) {
    return flattenedData;
  }

  const { sortField, sortOrder, type } = sortParams;

  const sortFunctions = {
    date: (a, b) => {
      const dateA = new Date(a[sortField]);
      const dateB = new Date(b[sortField]);
      return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
    },
  };

  const sortFunction = sortFunctions[type];
  if (!sortFunction) {
    console.warn(`Unsupported sort type: ${type}`);
    return flattenedData;
  }

  return flattenedData.map((el) => ({
    ...el,
    batchData: [...el.batchData].sort(sortFunction),
  }));
};

const batchDeadlinesSelector = createSelector(
  (state) => state.batchDeadlines,
  (batchDeadlines) => batchDeadlines
);

const stageDeliverablesSelector = createSelector(
  batchDeadlinesSelector,
  workflowStagesSelector,
  (state) => state.featureToggles,
  (state) => state.clientBatchDetails,
  (state) => state.batches.entities,
  (_a, workflowId) => workflowId,
  (_a, _b, _c, selectedStageTypes) => selectedStageTypes,
  (_a, _b, _c, _d, showFinal) => showFinal,
  (_a, _b, _c, _d, _e, selectedLanguage) => selectedLanguage,
  (_a, _b, _c, _d, _e, _f, selectedBatch) => selectedBatch,
  (_a, _b, _c, _d, _e, _f, _g, sortParams) => sortParams,
  (
    batchDeadlines,
    stages,
    featureToggles,
    clientBatchDetails,
    batchEntities,
    projectWorkflowId,
    selectedStageTypes,
    showFinal,
    selectedLanguage,
    selectedBatch,
    sortParams
  ) => {
    const tree = {};
    const flattened = {};

    // only use the stages that are in the workflow of the project and match the selected stageType
    stages.forEach(({ stageType, stageName, stageId, isFinal, workflowId }) => {
      if (
        selectedStageTypes.indexOf(stageType) > -1 &&
        workflowId === projectWorkflowId &&
        (showFinal ? isFinal : !isFinal)
      ) {
        if (!tree[stageId]) {
          tree[stageName] = {};
          flattened[stageName] = {
            stageId,
            stageName,
            stageType,
            batchData: [],
          };
        }
      }
    });

    // construct a total count object for each batch/languageCode combination
    const totalCount = {};
    clientBatchDetails.map((d) => {
      if (!totalCount[d.batchId]) totalCount[d.batchId] = {};
      if (!totalCount[d.batchId][d.languageCode])
        totalCount[d.batchId][d.languageCode] = 0;
      totalCount[d.batchId][d.languageCode] += d.count;
      return d;
    });

    // now loop over all the client batch details we have available
    clientBatchDetails.forEach((batchDetails) => {
      const {
        batchId,
        batchName,
        stageName,
        languageCode,
        count: deliverableCount,
        latestTransitionDate,
      } = batchDetails;
      const batch = batchEntities[batchId];

      // if the stage name exists e.g. only new content for review / approved
      if (flattened[stageName]) {
        const { stageId } = flattened[stageName];

        let deadline;

        if (!featureToggles.QCC_1728_batchDetails) {
          const batchDeadline = batchDeadlines.find(
            (bd) =>
              bd.batchId === Number(batchId) && bd.stageId === Number(stageId)
          );
          deadline = batchDeadline && batchDeadline.deadline;
        } else if (stageName.startsWith("Amended content for review")) {
          deadline = batch.amendedContentDueAt;
        } else if (
          stageName === "Client review" ||
          stageName.startsWith("New content for review")
        ) {
          deadline = batch.newContentFeedbackDueAt;
        }

        // and the language filter matches the batch details
        if (
          (selectedLanguage === "all" || selectedLanguage === languageCode) &&
          (selectedBatch === "all" || selectedBatch === batchId)
        ) {
          // add the details to our returned array of stage/batch details
          flattened[stageName].batchData.push({
            batchName,
            batchId,
            deliverableCount,
            languageCode,
            deadline,
            totalCount: totalCount[batchId][languageCode],
            latestTransitionDate,
          });
        }
      }
    });

    const result = sortData(Object.values(flattened), sortParams);

    return result;
  }
);

const mapDispatchToProps = (dispatch, ownProps) => ({
  selectLanguage: (selectedLanguage) =>
    dispatch(selectLanguageSuccess(selectedLanguage)),
});

const mapStateToProps = (state, ownProps) => {
  const { history, selectedStageTypes, showFinal, location, sortParams } =
    ownProps;
  const projectId = Number(ownProps.projectId);

  const batches = batchesByProjectId(state, projectId);
  const project = state.projects.entities[projectId] || {};

  // get the selectedLanguage and only add to stageData if the language matches
  const selectedLanguage = currentLanguageSelector(state, projectId);

  const selectedBatchId = state.dropdowns.batchId || "all";

  const languages = projectLanguagesWithFullNameSelector(
    state,
    Number(projectId)
  );
  const projectLanguages = projectLanguagesSelector(state, projectId);
  const languageNames = languageNamesFromLanguages(languages);

  const stageData = stageDeliverablesSelector(
    state,
    project.workflowId,
    projectId,
    selectedStageTypes,
    showFinal,
    selectedLanguage,
    selectedBatchId,
    sortParams
  );

  return {
    history,
    batches,
    stageData,
    projectLanguages,
    location,
    projectId,
    languageNames,
    selectedLanguage,
    featureToggles: state.featureToggles,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgressTab);
