import React from "react";
import { MentionsInput, Mention } from "react-mentions";
import "./Mentions.scss";

const Mentions = ({ data, onChange, value }) => {
  return (
    <div>
      <MentionsInput
        value={value}
        onChange={onChange}
        placeholder="Type your comment..."
        singleLine={false}
        className="mentions-input"
      >
        <Mention
          trigger="@"
          data={data}
          markup="@[__display__](id:__id__)"
          displayTransform={(id, display) => `${display}`}
          appendSpaceOnAdd={true}
          className="mentions-input__mention"
          style={{ color: "#DA0034" }}
        />
      </MentionsInput>
    </div>
  );
};

export default Mentions;
