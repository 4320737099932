import { createAction } from "redux-actions";
import { upsertData, removeData } from "../utils/normalize";
import { getGraphQL, postGraphQL } from "../../utils/fetch";
import handleErrors from "../utils/handleErrors";

// ------------------------------------
// Constants
// ------------------------------------
export const ADD_MENTION_SUCCESS = "ADD_MENTION_SUCCESS";
export const REMOVE_MENTION_SUCCESS = "REMOVE_MENTION_SUCCESS";
export const FETCH_MENTIONS_SUCCESS = "FETCH_MENTIONS_SUCCESS";

// ------------------------------------
// Actions
// ------------------------------------
export const addMentionSuccess = createAction(ADD_MENTION_SUCCESS);
export const removeMentionSuccess = createAction(REMOVE_MENTION_SUCCESS);
export const fetchMentionsSuccess = createAction(FETCH_MENTIONS_SUCCESS);

// ------------------------------------
// Action Creators with async/await
// ------------------------------------
export function fetchMentions() {
  return async (dispatch) => {
    const query = `query fetchMentions { 
      mentions { id, name } 
    }`;

    try {
      const json = await getGraphQL(query);
      dispatch(fetchMentionsSuccess(json.mentions));
    } catch (err) {
      handleErrors(err);
    }
  };
}

export function addMention(data) {
  return async (dispatch) => {
    const query = `mutation addMention($input: MentionInput) {
      addMention(input: $input) {
        id, name
      }
    }`;

    try {
      const json = await postGraphQL(query, { input: data }, "addMention");
      dispatch(addMentionSuccess(json.addMention));
    } catch (err) {
      handleErrors(err);
    }
  };
}

export function removeMention(mentionId) {
  return async (dispatch) => {
    const query = `mutation removeMention($input: MentionInput) {
      removeMention(input: $input) {
        id
      }
    }`;

    try {
      await postGraphQL(query, { input: { id: mentionId } }, "removeMention");
      dispatch(removeMentionSuccess(mentionId));
    } catch (err) {
      handleErrors(err);
    }
  };
}

// This function will update the mentions in the store with a batch of mentions
export function updateMentionsInStore(mentions) {
  return async (dispatch) => {
    try {
      dispatch(fetchMentionsSuccess(mentions));
    } catch (err) {
      handleErrors(err);
    }
  };
}

// ------------------------------------
// Initial State
// ------------------------------------
export const mentionInitialState = {
  entities: {},
  result: [],
};

// ------------------------------------
// Action Handlers
// ------------------------------------
export const mentionActionHandlers = {
  [FETCH_MENTIONS_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "id"),
  [ADD_MENTION_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "id"),
  [REMOVE_MENTION_SUCCESS]: (state, { payload }) => removeData(state, payload),
};

// ------------------------------------
// Reducer
// ------------------------------------
export default function mentionsReducer(state = mentionInitialState, action) {
  const handler = mentionActionHandlers[action.type];
  return handler ? handler(state, action) : state;
}
