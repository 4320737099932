import React, { useMemo, useEffect, useState, useCallback } from "react";
import styles from "./BatchSelector.module.scss";
import Dropdown from "../../../components/Dropdown/v4";
import { getLSItem, setLSItem } from "../../../utils/localStorage";
import parseQuery from "../../../utils/parseQuery";
import queryString from "query-string";

const MAX_CHARS = 18;
const DROPDOWN_NO_BATCH_SELECTED = "Batch";

const truncate = (str: string, maxLength = MAX_CHARS) => {
  if (!str) return DROPDOWN_NO_BATCH_SELECTED;

  if (str.length > maxLength) return str.substring(0, maxLength - 3) + "...";

  return str;
};

type History = {
  location: Location;
  replace: Function;
};
type Batch = {
  batchId: number;
  batchName: string;
};
type DropdownBatch = Omit<Batch, "batchId"> & {
  batchId?: number;
};
type Location = {
  search: string;
};

type BatchItemProps = {
  batchId?: number;
  batchName: string;
  updateBatch: (event: React.MouseEvent) => void;
};
type BatchSelectorProps = {
  batches: Batch[];
  onToggle: (value: boolean) => void;
  showAllButton?: boolean;
  selectBatch?: ({ batchId }: { batchId: number }) => void;
  history: History;
  localStorageKey: string;
  location: Location;
};

const BatchSelector = ({
  batches,
  onToggle,
  showAllButton,
  location,
  selectBatch,
  history,
  localStorageKey,
}: BatchSelectorProps) => {
  const paramBatchId = Number(parseQuery(location?.search).batchId);

  const batchesById = useMemo(() => {
    return batches.reduce((acc, { batchId, batchName }) => {
      acc[batchId] = {
        batchId,
        batchName: truncate(batchName),
      };
      return acc;
    }, {});
  }, [batches]);

  const [selectedBatch, setSelectedBatch] = useState(() => {
    const paramBatch = batchesById[paramBatchId];
    const lsBatchId = Number(getLSItem(localStorageKey));
    const lsBatch = batchesById[lsBatchId];

    // first priority is a batch id in the params, second is local storage, otherwise show the newest batch
    if (paramBatch) {
      return paramBatch;
    } else if (lsBatch) {
      return lsBatch;
    } else if (showAllButton) {
      return null;
    } else {
      return batches[0];
    }
  });

  useEffect(() => {
    const batchId = selectedBatch?.batchId;

    // Parse the current search query parameters
    const currentParams = queryString.parse(history.location.search);

    // Update the batchId in the query parameters, or add it if not present
    if (batchId) {
      currentParams.batchId = batchId;
    } else {
      delete currentParams.batchId; // Remove batchId if not selected
    }

    // Convert the updated parameters back to a query string
    const newSearch = queryString.stringify(currentParams);

    // Update local storage
    setLSItem(localStorageKey, batchId);

    // Call the selectBatch function with the new batchId
    if (selectBatch) {
      selectBatch({ batchId });
    }

    // Replace the history search with the new query string if it has changed
    if (history.location.search !== `?${newSearch}`) {
      history.replace({
        search: newSearch,
      });
    }
  }, [history, localStorageKey, selectBatch, selectedBatch]);

  const updateBatch = useCallback(
    (batchId) => {
      setSelectedBatch(batchesById[batchId]);
    },
    [batchesById]
  );

  const options = useMemo(
    () =>
      batches.reduce(
        (acc, { batchId, batchName }) => ({ ...acc, [batchId]: batchName }),
        {}
      ),
    [batches]
  );

  return (
    <div className={styles.container}>
      <Dropdown
        filterText={"filter by batch name"}
        onClick={updateBatch}
        options={options}
        title={selectedBatch ? selectedBatch.batchName : "Batches"}
        hideSelectAll={!showAllButton}
      />
    </div>
  );
};

export default BatchSelector;
