import { connect } from "react-redux";
import { createSelector } from "reselect";
import {
  commentGroupsByDeliverableSelector,
  CommentType,
} from "../../../../../modules/commentGroups";
import { nullObject } from "../../../../../utils/nullObjects";
import TitleBar from "./TitleBar";
import { getLatestTaskFieldContentV2 } from "../../../../../modules/taskFieldContent";
import { stageById } from "../../../../../utils/entitySelector";

const mapStateToPropsFactory = () => {
  const fieldCommentGroupSelector = createSelector(
    commentGroupsByDeliverableSelector,
    (_state, _delId, _stageId, taskFieldId) => taskFieldId,
    (commentGroups, taskFieldId) => {
      const group = commentGroups.find(
        ({ taskFieldId: groupTaskFieldId, commentType }) =>
          groupTaskFieldId === taskFieldId && commentType === CommentType.Field
      );

      return group || nullObject;
    }
  );

  return function mapStateToProps(state, ownProps) {
    const { deliverableId, stageId, taskFieldId, initialClientTeam } = ownProps;
    const tfc = getLatestTaskFieldContentV2(state, deliverableId, stageId)[
      taskFieldId
    ];
    const { isFinal } = stageById(state, tfc && tfc.stageId);

    const { commentGroupId: fieldCommentGroupId, comments } =
      fieldCommentGroupSelector(state, deliverableId, stageId, taskFieldId);

    return {
      approvedAndModified: isFinal,
      commentLength: comments ? comments.length : 0,
      fieldCommentGroupId,
      initialClientTeam,
    };
  };
};

export default connect(mapStateToPropsFactory)(TitleBar);
