import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import PageHeader from "../../../../../../../../components/PageHeader";
import Errors from "../../../../../../../../components/Errors";
import AssignmentEditByAssignment from "../../../../../../../../components/AssignmentEdit/_decorators/ByAssignment";
import ProjectInfo from "../../../../../../../../components/PageHeader/ProjectInfo";
import BatchFeedbackButton from "../../../../../../../../components/AssignmentEdit/_components/BatchFeedbackButton";
import styles from "./AssignmentsShow.module.scss";

export default function AssignmentsShow(props) {
  const {
    assignment,
    history,
    errors,
    listUrl,
    project: {
      accountName,
      orderFormNumber,
      parentAccountName,
      projectId,
      projectName,
    },
  } = props;

  const { batchId, languageCode, personFullName, stageId } = assignment;

  return (
    <div>
      <Helmet>
        <title>Tasks</title>
      </Helmet>

      {errors && errors.display && <Errors />}

      <PageHeader
        breadCrumbItems={[
          { url: `/admin`, text: "Parent accounts" },
          {
            text: `${parentAccountName} | ${accountName} | ${orderFormNumber}`,
          },
          { url: `/admin/projects/${projectId}`, text: projectName || "" },
          { url: `${listUrl}`, text: `Assignment list (${personFullName})` },
          { text: "Task" },
        ]}
        title={projectName || ""}
      >
        <div className={styles.headerRight}>
          <ProjectInfo url={`/admin/projects/${projectId}/project-info`} />
          <BatchFeedbackButton
            batchId={batchId}
            languageCode={languageCode}
            stageId={stageId}
            projectId={projectId}
          />
        </div>
      </PageHeader>

      <AssignmentEditByAssignment
        assignment={assignment}
        history={history}
        listUrl={listUrl}
        projectId={projectId}
      />
    </div>
  );
}

AssignmentsShow.propTypes = {
  assignment: PropTypes.shape({
    batchId: PropTypes.number,
    languageCode: PropTypes.string,
    personFullName: PropTypes.string,
    stageId: PropTypes.number,
  }),
  errors: PropTypes.object,
  history: PropTypes.object,
  listUrl: PropTypes.string.isRequired,
  project: PropTypes.shape({
    accountName: PropTypes.string,
    orderFormNumber: PropTypes.string,
    parentAccountName: PropTypes.string,
    projectId: PropTypes.number,
    projectName: PropTypes.string,
  }),
};
