import React, { memo } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import PageHeader from "../../../../../../../../../components/PageHeader";
import Errors from "../../../../../../../../../components/Errors";

import styles from "./AssignmentsShow.module.scss";
import AssignmentEditByAssignment from "../../../../../../../../../components/AssignmentEdit/_decorators/ByAssignment";
import BatchFeedbackButton from "../../../../../../../../../components/AssignmentEdit/_components/BatchFeedbackButton";
import ProjectInfo from "../../../../../../../../../components/PageHeader/ProjectInfo";
import { Redirect, useLocation } from "react-router";
import OverlayLoading from "../../../../../../../../../components/OverlayLoading/v2";
function AssignmentsShow(props) {
  const {
    assignment,
    errors,
    history,
    listUrl,
    project: { accountName, projectId, projectName },
    stageName,
    dataReady,
    asyncFetched,
  } = props;

  const location = useLocation();
  if (!dataReady && !asyncFetched) return <OverlayLoading />;

  const { assignmentGroupId, batchId, languageCode, stageId, assignmentId } =
    assignment;

  const currentPath = location.pathname;
  const updatedPath = `/freelancer/projects/${projectId}/assignments/${assignmentGroupId}/tasks/${assignmentId}`;

  if (currentPath !== updatedPath) {
    return <Redirect to={updatedPath} />;
  }

  return (
    <div>
      <Helmet>
        <title>Tasks</title>
      </Helmet>

      {errors && errors.display && <Errors />}

      <PageHeader
        breadCrumbItems={[
          { url: "/freelancer", text: "Dashboard" },
          {
            url: listUrl,
            text: `Assignment list (${stageName} | ${projectName} | ${accountName})`,
          },
          { text: "Task" },
        ]}
        title={projectName || ""}
      >
        <div className={styles.headerRight}>
          <ProjectInfo url={`/freelancer/projects/${projectId}/project-info`} />
          <BatchFeedbackButton
            batchId={batchId}
            languageCode={languageCode}
            stageId={stageId}
            projectId={projectId}
          />
        </div>
      </PageHeader>

      <AssignmentEditByAssignment
        assignment={assignment}
        gradeAssignmentGroupId={assignmentGroupId}
        history={history}
        listUrl={listUrl}
        projectId={projectId}
      />
    </div>
  );
}

AssignmentsShow.propTypes = {
  assignment: PropTypes.object,
  errors: PropTypes.object,
  history: PropTypes.object.isRequired,
  listUrl: PropTypes.string,
  project: PropTypes.object,
  stageName: PropTypes.string,
};

export default memo(AssignmentsShow);
