import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./WordsChecker.module.scss";
import WordList from "./WordList";
import classNames from "classnames";

const noBoundaryLanguages = ["zh-Hans", "zh-Hant", "ja-JP", "ko-KR"];

/**
 * Preprocesses text by removing spaces and punctuation, keeping only letters and numbers.
 * @param {string} text - The text to preprocess.
 * @returns {string} The preprocessed text.
 */
const preprocessContent = (text) => {
  return typeof text === "string" ? text.replace(/[\s,.;!?]+/g, "") : "";
};

/**
 * Checks if a word (or part of it) exists in any element of the content array.
 * @param {string} word - The word to search for.
 * @param {string[]} contentArray - The array of strings to search in.
 * @param {string} languageCode - The language code to determine processing method.
 * @returns {boolean} True if the word is found, false otherwise.
 */
const hasWord = (word, contentArray, languageCode) => {
  const shouldPreprocess = noBoundaryLanguages.includes(languageCode);
  const processedWord = shouldPreprocess
    ? preprocessContent(word)
    : word.toLowerCase();

  // Create regex outside the loop for non-preprocessed searches
  const regex = !shouldPreprocess ? new RegExp(word, "i") : null;

  for (const text of contentArray) {
    if (typeof text !== "string") continue;

    if (shouldPreprocess) {
      const processedText = preprocessContent(text);
      if (processedText.includes(processedWord)) return true;
    } else {
      if (regex.test(text)) return true;
    }
  }

  return false;
};

class WordsChecker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showKeywords: true,
    };
  }

  componentDidMount() {
    this.wordsMatch();
  }

  componentDidUpdate() {
    this.wordsMatch();
  }

  /**
   * Generates an array of words with a flag indicating whether each word is found in the content.
   * @returns {Array} An array of objects with each word and its hasWord flag.
   */
  wordsMatch = () => {
    const { content, words, languageCode } = this.props;
    return words.map((word) => ({
      word,
      hasWord: hasWord(word, content, languageCode),
    }));
  };

  toggleWords = () => {
    this.setState({ showKeywords: !this.state.showKeywords });
  };

  render() {
    const wordsArray = this.wordsMatch();

    const style = classNames({
      [styles.visible]: this.state.showKeywords,
      [styles.hidden]: !this.state.showKeywords,
    });

    return (
      <div className={styles.wordsContainer}>
        <div className={styles.wordNav} onClick={this.toggleWords}>
          <span>{this.props.title}</span>
          <svg height="15px" width="15px">
            {this.state.showKeywords ? (
              <path d="M1 7h14v2h-14v-2z" fill="#da0034" />
            ) : (
              <path d="M16 5.5l-1-1-7 7-7-7-1 1 8 8 8-8z" fill="#da0034" />
            )}
          </svg>
        </div>
        <div className={style}>
          <WordList
            emptyMessage={this.props.emptyMessage}
            highlightClass={this.props.highlightClass}
            noUsedMessage={this.props.noUsedMessage}
            wordsArray={wordsArray}
          />
        </div>
      </div>
    );
  }
}

WordsChecker.propTypes = {
  content: PropTypes.array.isRequired,
  words: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  highlightClass: PropTypes.string.isRequired,
  emptyMessage: PropTypes.string.isRequired,
  noUsedMessage: PropTypes.string.isRequired,
  languageCode: PropTypes.string.isRequired,
};

export default WordsChecker;
