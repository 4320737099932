import React, { Component } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";
import { isTaskFormatTM } from "../../../modules/taskFields";
import { getTranslationKey } from "../../../modules/translations";
import Task from "./Task";
import TaskTM from "./TaskTM";
import { getLSItemV2 } from "../../../utils/localStorage";
import createTMResponses from "../../../modules/translationMemoryResponses";
class Tasks extends Component {
  constructor(props) {
    super(props);

    this.taskComponents = {};
    this.debounceSaveAll = debounce(this.saveAll, 1500);
    this.tmResponsesToSave = {};
    this.debounceSaveTMResponses = debounce(this.saveAllTMResponses, 400);

    this.state = {
      confirmedSegmentBlocks: {},
    };
  }

  componentWillUnmount() {
    this.saveAll();
    this.saveAllTMResponses();
  }

  componentDidMount() {
    this.fetchTranslations();

    // Create a map from a given draft key to an editor ref and also store each
    // key in order so from another given key we can find next/prev refs easy
    this.segmentEditorRefs = {};
    this.orderedSegmentKeys = [];

    this.props.tasks
      .filter((t) => isTaskFormatTM(t.format))
      // merge all task field blocks into one array
      .reduce((acc, { sourceField: { rawContent } }) => {
        if (rawContent) {
          acc.push(...rawContent.blocks);
        }
        return acc;
      }, [])
      // create the ref object and key list
      .forEach(({ key }) => {
        // the ref will be set when the task component mounts
        this.segmentEditorRefs[key] = null;
        this.orderedSegmentKeys.push(key);
      });
  }

  /**
   * Update object of editor refs
   */
  updateSegmentEditorRef = (segmentKey, editorRef) => {
    this.segmentEditorRefs[segmentKey] = editorRef;
  };

  /**
   * Focus the next available unconfirmed segment
   */
  focusNextUnconfirmedEditor = (confirmedSegmentKey) => {
    // ordered array of unconfirmed segment refs
    const refArr = this.orderedSegmentKeys.reduce((acc, id) => {
      // a ref will only exist if the segment is unconfirmed
      const ref = this.segmentEditorRefs[id];

      // only retrieve unconfirmed and the currently actioned segment
      if (ref || id === confirmedSegmentKey) {
        acc.push({ id, ref });
      }

      return acc;
    }, []);

    // get the index of the currently confirmed segment
    const currentIndex = refArr.findIndex((t) => t.id === confirmedSegmentKey);

    // check if we are not at the end of the unconfirmed array
    if (refArr[currentIndex + 1]) {
      this.focusRef(refArr[currentIndex + 1].ref);
      // if there are no more unconfirmed later, try to focus the first element
    } else if (refArr[0] && refArr[0].ref) {
      this.focusRef(refArr[0].ref);
    }
  };

  /**
   * This will focus an element and scroll it into the center of the screen
   */
  focusRef = (ref) => {
    ref.focus();

    // position the scroll so the editor is in the middle of the screen
    const scrollY =
      ReactDOM.findDOMNode(ref).offsetTop - window.innerHeight / 2;

    window.scrollTo({
      left: 0,
      top: scrollY,
      behavior: "smooth",
    });
  };

  /**
   * Mark a confirmed block of content for segments matching a given source text
   *
   * @param {string}  sourceText
   * @param {Object}  blockToConfirm DraftJS content block
   */
  setConfirmedSegmentBlock = (sourceText, blockToConfirm) => {
    const key = getTranslationKey(sourceText);

    this.setState((state) => {
      return {
        confirmedSegmentBlocks: {
          ...state.confirmedSegmentBlocks,
          [key]: blockToConfirm,
        },
      };
    });
  };

  /**
   * @param   {string}  sourceText
   * @param   {string}  targetText
   * @returns {Object}  DraftJS content block
   */
  getConfirmedSegmentBlock = (sourceText) => {
    const key = getTranslationKey(sourceText);
    return this.state.confirmedSegmentBlocks[key];
  };

  /**
   * Fetch translations for all unique translation memory segments
   */
  fetchTranslations() {
    const { localStorageKeys, tasks, translationParams } = this.props;
    const localTranslations = getLSItemV2(localStorageKeys) || {};
    const includeMT = new Set();
    const excludeMT = new Set();

    tasks.forEach(
      ({ format, rawContent, sourceField: { rawContent: sourceContent } }) => {
        if (!isTaskFormatTM(format) || !sourceContent) return;

        const targetBlocksByKey = !rawContent
          ? {}
          : rawContent.blocks.reduce((acc, cur) => {
              acc[cur.key] = cur;
              return acc;
            }, {});

        sourceContent.blocks.forEach(({ key, text }) => {
          const localTranslation = localTranslations[text];

          if (!targetBlocksByKey[key]) {
            const sanitised = getTranslationKey(text);

            // if there is unconfirmed source text do not request for MT
            if (localTranslation) {
              excludeMT.add(sanitised);
            } else {
              includeMT.add(sanitised);
            }
          }
        });
      }
    );

    if (includeMT.size + excludeMT.size > 0) {
      this.props.fetchTranslations(translationParams, {
        includeMT: [...includeMT],
        excludeMT: [...excludeMT],
      });
    }
  }

  /**
   * This function will save a task immediately
   * Useful for adding comments / updating image state
   *
   * @param {Object} taskState the task field content row to insert
   */
  saveSingleTask = async (taskFieldContentRow) => {
    const { deliverableId, personId, stageId } = this.props;

    const data = {
      deliverableId,
      personId,
      stageId,
      ...taskFieldContentRow,
    };

    await this.props.saveContent([data]);
  };

  /**
   * Returns an array of task field content rows for all task fields
   */
  getAllRows = () => {
    const taskStates = this.getAllTaskStates();
    return this.getContentRowsFromTaskStates(taskStates);
  };

  /**
   * Returns a list of task field content rows ready to insert to the database
   */
  getContentRowsFromTaskStates = (taskStates) => {
    const { deliverableId, stageId, personId } = this.props;

    // add the extra data to send to the server for task field content rows
    return taskStates.map(({ content, rawContent, taskFieldId }) => ({
      content,
      deliverableId,
      personId,
      rawContent,
      stageId,
      taskFieldId,
    }));
  };

  /**
   * Updates the total word count for all tasks
   */
  onWordCountUpdate = () => {
    const totalWordCount = Object.values(this.taskComponents).reduce(
      (total, taskComponent) => {
        if (!taskComponent) return total;
        return total + (taskComponent.wordCount || 0);
      },
      0
    );

    this.props.onWordCountUpdate(totalWordCount);
  };

  /**
   * Returns an array of task state objects which contain: { content, rawContent, hasChanged }
   */
  getAllTaskStates = () => {
    if (!this.props.isCurrentStage) return [];
    return Object.entries(this.taskComponents).map(
      ([_, task]) => task.taskState
    );
  };

  discardChanges = () => {
    return Object.entries(this.taskComponents).forEach(([_, task]) =>
      task.discardChanges()
    );
  };

  /**
   * Saves all changed task fields
   */
  saveAll = async () => {
    if (!this.props.isEditable) return;

    const changedTasks = Object.values(this.taskComponents)
      .filter((t) => t && t.taskEditor)
      .map(({ taskEditor }) => {
        const taskState = taskEditor.taskState;
        return { ...taskEditor, ...taskState };
      })
      .filter((t) => t.hasChanged);

    if (changedTasks.length === 0) return;

    const taskFieldContentRows =
      this.getContentRowsFromTaskStates(changedTasks);

    try {
      await this.props.saveContent(taskFieldContentRows);

      changedTasks.forEach((t) => {
        const { rawContent } = taskFieldContentRows.find(
          (tfc) => tfc.taskFieldId === t.taskFieldId
        );

        t.onSave(rawContent);
      });
    } catch (e) {} // error is already handled, but passed down, forget about it here
  };

  saveAllTMResponses = async () => {
    const responses = Object.values(this.tmResponsesToSave);

    if (responses.length === 0) return;

    const {
      // unused params
      accountId,
      verticalType,
      targetLanguage,
      personId,

      sourceLanguage: sourceLanguageCode,
      translatedLanguage: translatedLanguageCode,

      ...context
    } = this.props.translationParams;

    const request = {
      context: {
        ...context,
        sourceLanguageCode,
        translatedLanguageCode,
      },
      responses,
    };

    await createTMResponses(request);

    // reset the responses to save after the server has responded
    this.tmResponsesToSave = {};
  };

  addTMResponses = (responses) => {
    responses.forEach((response) => {
      const { sourceText, translatedText, certainty } = response;
      const key = `${sourceText}-${translatedText}-${certainty}`;

      // store by a unique key to remove duplicates
      this.tmResponsesToSave[key] = response;
    });

    this.debounceSaveTMResponses();
  };

  checkAllGrammars = () => {
    Object.values(this.taskComponents).forEach((taskEditor) => {
      taskEditor.checkGrammar();
    });
  };

  render() {
    const {
      addToDictionary,
      accountId,
      bannedWords,
      deliverableId,
      grammarChecks,
      isCommentable,
      isCurrentStage,
      isEditable,
      isLocalisationProject,
      keywords,
      featureToggles,
      languageCode,
      localStorageKeys,
      personId,
      shouldShowTM,
      projectId,
      stageId,
      tasks,
      translationParams,
      translations,
      rateBandId,
      taskFieldRateBands,
      removeSuggestion,
      allowGrammarCheck,
      glossaryWords,
      glossarySourceWords,
      initialClientTeam,
    } = this.props;

    return (
      <div>
        {tasks.map((task) => {
          const { fieldName, format, rawContent, taskFieldId, sourceField } =
            task;

          // hide task fields based on task_field_rate_band values
          const includeTask =
            taskFieldRateBands[taskFieldId] &&
            taskFieldRateBands[taskFieldId].includes(rateBandId);
          if (featureToggles.taskFieldRateBands && !includeTask) return null;

          const sharedFields = {
            deliverableId,
            isCommentable,
            isEditable,
            onWordCountUpdate: this.onWordCountUpdate,
            personId,
            stageId,
            taskFieldId,
          };

          const isRegularTask = ["HTML", "Text", "Image"].includes(format);

          // use shouldShowTM as an override for assignments not at the right stage to show the TM view
          if (isRegularTask || !shouldShowTM) {
            return (
              <Task
                {...task}
                key={`${deliverableId}-${taskFieldId}`}
                ref={(component) =>
                  (this.taskComponents[taskFieldId] = component)
                }
                grammarChecks={grammarChecks}
                glossaryWords={glossaryWords}
                bannedWords={bannedWords}
                debounceSaveAll={this.debounceSaveAll}
                isCurrentStage={isCurrentStage}
                isLocalisationProject={isLocalisationProject}
                keywords={keywords}
                projectId={projectId}
                saveSingleTask={this.saveSingleTask}
                sourceContent={sourceField.fieldValue}
                discardChanges={this.props.discardChanges}
                removeSuggestion={removeSuggestion}
                allowGrammarCheck={allowGrammarCheck}
                languageCode={languageCode}
                addToDictionary={addToDictionary}
                checkAllGrammars={this.checkAllGrammars}
                glossarySourceWords={glossarySourceWords}
                processTransitionGrammarCheck={
                  this.props.processTransitionGrammarCheck
                }
                {...sharedFields}
                initialClientTeam={initialClientTeam}
              />
            );
          } else {
            const hasSourceRawContent =
              typeof sourceField.rawContent !== "undefined";

            return (
              <TaskTM
                key={`${deliverableId}-${taskFieldId}-${hasSourceRawContent}`}
                ref={(component) =>
                  (this.taskComponents[taskFieldId] = component)
                }
                maxCharacters={task.maxCharacters}
                accountId={accountId}
                addTMResponses={this.addTMResponses}
                confirmedSegmentBlocks={this.state.confirmedSegmentBlocks}
                fieldName={fieldName}
                focusNextUnconfirmedEditor={this.focusNextUnconfirmedEditor}
                getConfirmedSegmentBlock={this.getConfirmedSegmentBlock}
                isEditable={isEditable}
                localStorageKeys={localStorageKeys}
                saveContent={this.saveSingleTask}
                setConfirmedSegmentBlock={this.setConfirmedSegmentBlock}
                sourceFieldRawContent={sourceField.rawContent}
                taskFieldRawContent={rawContent}
                translationParams={translationParams}
                translations={translations}
                updateSegmentEditorRef={this.updateSegmentEditorRef}
                isCurrentStage={isCurrentStage}
                glossarySourceWords={glossarySourceWords}
                {...sharedFields}
                initialClientTeam={initialClientTeam}
              />
            );
          }
        })}
      </div>
    );
  }
}

Tasks.propTypes = {
  accountId: PropTypes.number.isRequired,
  bannedWords: PropTypes.array,
  deliverableId: PropTypes.number,
  fetchTranslations: PropTypes.func.isRequired,
  isCommentable: PropTypes.bool.isRequired,
  isCurrentStage: PropTypes.bool.isRequired,
  isEditable: PropTypes.bool.isRequired,
  isLocalisationProject: PropTypes.bool.isRequired,
  keywords: PropTypes.array,
  localStorageKeys: PropTypes.array.isRequired,
  onWordCountUpdate: PropTypes.func.isRequired,
  personId: PropTypes.number.isRequired,
  projectId: PropTypes.number.isRequired,
  saveContent: PropTypes.func.isRequired,
  shouldShowTM: PropTypes.bool.isRequired,
  stageId: PropTypes.number,
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string,
      fieldName: PropTypes.string.isRequired,
      format: PropTypes.string.isRequired,
      maxCharacters: PropTypes.number,
      maxWords: PropTypes.number,
      minCharacters: PropTypes.number,
      minWords: PropTypes.number,
      qualityCheck: PropTypes.bool,
      rawContent: PropTypes.object,
      sourceContent: PropTypes.string,
      taskFieldId: PropTypes.number.isRequired,
    })
  ).isRequired,
  translationParams: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
};

export default Tasks;
