import React, { Component } from "react";
import PropTypes from "prop-types";
import { mergeArrayData } from "../../../../utils/objects";
import { plaintextWordCount } from "../../../../utils/counts";
import {
  getTranslation,
  TranslationCertainty,
} from "../../../../modules/translations";
import {
  createEditorState,
  createRawContent,
  editorStateToRaw,
  editorStateToText,
  getFirstBlock,
  safeConvertFromRaw,
} from "../editorUtils";
import { EditorState, CompositeDecorator } from "draft-js";
import linkPlugin from "../_plugins/LinkEditor/LinkDecorator";
import { createInlineCommentsPlugin } from "../_plugins/InlineComments";
import Toolbar from "../../../DraftJS/Toolbar";
import Counter from "../_components/Counter";
import Segment from "./Segment";
import styles from "./TaskTM.module.scss";
import {
  getLSItemV2,
  setLSItemV2,
  removeLSItemV2,
} from "../../../../utils/localStorage";
import throttle from "lodash/throttle";
import TitleBar from "../_components/TitleBar";
import { getCharCount } from "../_components/Counter/Counter";
import { glossaryWordsHighlightPlugin } from "../Task/TaskEditor/_plugins/WordHighlight/WordHighlight";
import { stateToHTML } from "draft-js-export-html";

class TaskTM extends Component {
  state = {
    activeEditorKey: null,
    segments: {},
    sourceWordCount: 0,
    targetWordCount: 0,
    targetCharCount: 0,
  };

  savedRawContent = null;

  // since we throttle each request to local storage we need to keep track of the tasks to save
  lsTaskKeysToSave = {};
  // LS needs to be saved to frequently to change previous translations
  savedToLocalStorageThrottled = throttle(
    () => {
      this.saveToLocalStorage();
    },
    200,
    { leading: false }
  );

  /**
   * Update a single segment in the state
   *
   * @param key the draft js key of the segment to change
   * @param segment the updated segment options
   */
  updateSegment = (key, segment) => {
    this.setState({
      segments: { ...this.state.segments, [key]: segment },
    });
  };

  /**
   * Updates the segment data in the state
   *
   * @param {Object}    segments  segments indexed by key
   * @param {Function}  [callback]
   */
  updateSegments = (segments, callback) => {
    if (segments === this.state.segments) {
      if (callback) callback();
      return;
    }

    const { targetWordCount: prevWordCount } = this.state;
    const targetWordCount = Object.values(segments).reduce(
      (acc, { targetEditorState }) =>
        acc + plaintextWordCount(editorStateToText(targetEditorState)),
      0
    );

    const targetCharCount = Object.values(segments).reduce(
      (acc, { targetEditorState }) => acc + getCharCount(targetEditorState),
      0
    );

    this.setState(
      {
        segments,
        targetWordCount,
        targetCharCount,
      },
      () => {
        if (targetWordCount !== prevWordCount) this.props.onWordCountUpdate();
        if (callback) callback();
      }
    );
  };

  updateSegmentEditorRef = (segmentKey, editorRef) => {
    this.props.updateSegmentEditorRef(segmentKey, editorRef);
  };

  /**
   * Confirm a content block
   *
   * @param {string}  sourceText
   * @param {Object}  targetBlock
   */
  confirmSegment = async (sourceText, targetBlock) => {
    this.props.setConfirmedSegmentBlock(sourceText, targetBlock);

    // remove as an item to be saved
    delete this.lsTaskKeysToSave[targetBlock.key];
    // remove from local storage as we don't need it anymore
    removeLSItemV2([...this.props.localStorageKeys, sourceText]);

    await this.props.createTranslation({
      ...this.props.translationParams,
      sourceSegment: sourceText,
      translatedSegment: targetBlock.text,
    });

    this.props.focusNextUnconfirmedEditor(targetBlock.key);
  };

  /**
   * @param {string} sourceText
   */
  unconfirmSegment = (sourceText, block) => {
    this.props.setConfirmedSegmentBlock(sourceText, null);
    setLSItemV2([...this.props.localStorageKeys, sourceText], block);
  };

  /**
   * Replaces the given segment's targetEditorState with editorState containing
   * the given text
   *
   * @param   {Object}    segment     segment
   * @param   {Object}    targetBlock DraftJS content block to populate the segment with
   * @param   {Object[]}  inlineStyleRanges DraftJS inline style ranges
   * @returns {Object}    updated segment
   */
  updateSegmentWithContent(segment, targetBlock) {
    const { populatedBlock } = segment;
    if (populatedBlock === targetBlock) return segment;

    const { text, inlineStyleRanges = [] } = targetBlock;

    const { compositeDecorators } = this.createDecorators(segment.key);
    const targetRawContent = editorStateToRaw(segment.targetEditorState);

    targetRawContent.blocks[0] = {
      ...targetRawContent.blocks[0],
      text,
      inlineStyleRanges,
    };

    const targetEditorState = EditorState.createWithContent(
      safeConvertFromRaw(targetRawContent),
      compositeDecorators
    );

    return {
      ...segment,
      populatedBlock: targetBlock,
      targetEditorState,
    };
  }

  saveContent = async (content, rawContentString) => {
    await this.props.saveContent({
      content: content,
      rawContent: rawContentString,
      taskFieldId: this.props.taskFieldId,
    });

    this.savedRawContent = rawContentString;
  };

  /**
   * Create task field content containing all confirmed segments
   */
  saveConfirmedSegments = () => {
    // Get confirmed content
    const { segments } = this.state;
    const confirmedBlocks = Object.values(segments)
      // include any empty lines for spacing and formatting
      .filter((s) => s.isConfirmed || s.isEmptyLine)
      .map(({ targetEditorState }) => getFirstBlock(targetEditorState));

    // figure out if we still need to save the content
    if (confirmedBlocks.length === 0) {
      // If the previous version of savedRawContent had content but now there
      // are no confirmed blocks we need to save and update the DB
      if (this.savedRawContent !== "{}") {
        this.saveContent("", null);
      }
      return;
    }

    const rawContent = createRawContent(confirmedBlocks);
    const rawContentString = JSON.stringify(rawContent);

    if (rawContentString === this.savedRawContent) return;

    const content = safeConvertFromRaw(rawContent);
    const contentAsHTML = stateToHTML(content);

    this.saveContent(contentAsHTML, rawContentString);
  };

  createDecorators(key) {
    // we need to attach a key to the event to update one of the segmente rwos
    const openCommentBoxHandler = (
      positionRect,
      type,
      commentGroupId,
      selection
    ) => {
      this.openCommentBox(key, positionRect, type, commentGroupId, selection);
    };

    const inlineCommentsPlugin = createInlineCommentsPlugin(
      this.props.commentGroups,
      openCommentBoxHandler,
      this.props.taskFieldId
    );
    const decorators = [
      linkPlugin,
      inlineCommentsPlugin,
      glossaryWordsHighlightPlugin(this.props.glossarySourceWords),
    ];
    const compositeDecorators = new CompositeDecorator(decorators);
    return { decorators, compositeDecorators };
  }

  componentDidMount = () => {
    const {
      sourceFieldRawContent,
      localStorageKeys,
      taskFieldRawContent = {},
    } = this.props;
    this.savedRawContent = JSON.stringify(taskFieldRawContent);

    if (!sourceFieldRawContent) return;

    // convert the target task field content to a map which uses the draft js keys to reference the source field
    const targetRawContentByKey =
      taskFieldRawContent && taskFieldRawContent.blocks
        ? taskFieldRawContent.blocks.reduce((arr, block) => {
            arr[block.key] = createRawContent(block);
            return arr;
          }, {})
        : {};

    let sourceWordCount = 0;
    const localTranslations = getLSItemV2(localStorageKeys) || {};

    // convert sourceContent blocks into segments
    const segments = sourceFieldRawContent.blocks.reduce((acc, block) => {
      const { key, type } = block;
      const sourceText = block.text.trim().replace(/\u00AD/g, "");

      const targetRawContent = targetRawContentByKey[key];
      const isConfirmed = typeof targetRawContent !== "undefined";
      const isEmptyLine = sourceText.length === 0;

      const localTranslation = localTranslations[sourceText] || {};

      // The default type/key should come from the source segment
      // But default text/styles should come from local storage of the segment
      const { inlineStyleRanges, text } =
        typeof localTranslation === "string"
          ? // TODO: Future fix: https://quillcontent.atlassian.net/browse/QCC-1881
            // we parse the translation if it's text because we need to handle a
            // bug where the translations were saved as text instead of an object
            JSON.parse(localTranslation)
          : localTranslation;

      // if we have no saved content we need to construct a block with a
      // specific set of options
      const defaultBlock = {
        inlineStyleRanges,
        key,
        text,
        type,
      };

      const { decorators, compositeDecorators } = this.createDecorators(key);
      const sourceEditorState = createEditorState(
        createRawContent(block),
        compositeDecorators
      );
      const targetEditorState = createEditorState(
        targetRawContent,
        compositeDecorators,
        defaultBlock
      );

      sourceWordCount += plaintextWordCount(sourceText);

      const segment = {
        decorators,
        key,
        isConfirmed,
        isEmptyLine,
        populatedBlock: {}, // the content block the segment was last auto-populated with
        selectedCommentGroup: null,
        sourceEditorState,
        sourceText,
        targetEditorState,
        translation: {},
      };

      if (isConfirmed) {
        this.props.setConfirmedSegmentBlock(
          sourceText,
          getFirstBlock(targetEditorState)
        );
      }

      acc[key] = segment;
      return acc;
    }, {});

    this.setState({ sourceWordCount });
    this.updateSegments(this.populateTranslations(segments));
  };

  componentDidUpdate({
    confirmedSegmentBlocks: prevConfirmedSegmentBlocks,
    translations: prevTranslations,
    commentGroups: prevCommentGroups,
  }) {
    const confirmedSegmentsChanged =
      prevConfirmedSegmentBlocks !== this.props.confirmedSegmentBlocks;
    let { segments } = this.state;

    if (prevTranslations !== this.props.translations) {
      segments = this.populateTranslations(segments);
    }

    if (confirmedSegmentsChanged) {
      segments = this.populateConfirmedText(segments);
    }

    if (prevCommentGroups !== this.props.commentGroups) {
      this.updateDecorators();
    }

    this.updateSegments(segments, () => {
      if (confirmedSegmentsChanged) {
        this.saveConfirmedSegments();
      }
    });
  }

  componentWillUnmount() {
    this.saveToLocalStorage();
  }

  /**
   * Populate segments with their confirmed text
   *
   * @param   {Object}  segments segments indexed by key
   * @returns {Object}  updated segment, indexed by key
   */
  populateConfirmedText(segments) {
    const updatedSegments = Object.values(segments).reduce((acc, segment) => {
      const { sourceText } = segment;
      const confirmedBlock = this.props.getConfirmedSegmentBlock(sourceText);

      if (confirmedBlock) {
        const updatedSegment = this.updateSegmentWithContent(
          segment,
          confirmedBlock
        );
        if (updatedSegment !== segment) {
          acc.push({
            // Ideally we should really check if content has changed in future stage, if we need to show that the segment was updated
            ...(this.props.isCurrentStage ? updatedSegment : segment),
            isConfirmed: true,
          });
        }
      } else if (segment.isConfirmed) {
        acc.push({
          ...segment,
          isConfirmed: false,
        });
      }

      return acc;
    }, []);

    return mergeArrayData(segments, updatedSegments, "key");
  }

  /**
   * This updates all segment editor states with an updated version of the
   * decorators which include the inline feedback highlighting
   */
  updateDecorators = () => {
    const segments = Object.entries({ ...this.state.segments }).reduce(
      (acc, [key, segment]) => {
        const { decorators, compositeDecorators } = this.createDecorators(key);
        const withDecorators = EditorState.set(segment.targetEditorState, {
          decorator: compositeDecorators,
        });

        // update editor state with new decorators for comment highlighting
        acc[key] = {
          ...segment,
          decorators,
          targetEditorState: withDecorators,
        };

        return acc;
      },
      {}
    );

    this.setState({
      segments,
    });
  };

  /**
   * Populate target segments with current translations
   *
   * @param   {Object}  segmentMap segments indexed by key
   * @returns {Object}  updated segmentMap
   */
  populateTranslations(segmentMap) {
    const { addTMResponses, translations, setConfirmedSegmentBlock } =
      this.props;

    const updatedSegments = Object.values(segmentMap).reduce((acc, segment) => {
      const { isConfirmed, sourceText } = segment;
      if (isConfirmed) return acc; // no-changes

      // Get translation
      const translation = getTranslation(translations, sourceText);
      if (!translation) return acc; // no changes

      const { target, certainty } = translation;
      const populatedSegment = this.updateSegmentWithContent(segment, {
        text: target,
      });

      // Auto-confirm if it's a full match
      if (certainty === TranslationCertainty.Full) {
        setConfirmedSegmentBlock(
          sourceText,
          getFirstBlock(populatedSegment.targetEditorState)
        );
      }

      acc.push({ ...populatedSegment, certainty });
      return acc;
    }, []);

    if (updatedSegments.length === 0) {
      return segmentMap; // no changes
    }

    // We need to keep a track of what was sent back from the TM as this data
    // is required for payments
    const responses = updatedSegments.map((segment) => {
      const {
        sourceText,
        populatedBlock: { text },
        certainty,
      } = segment;

      return {
        sourceText,
        translatedText: text,
        certainty,
      };
    });
    addTMResponses(responses);

    // update the already stored blocks with the newly updated blocks
    return mergeArrayData(segmentMap, updatedSegments, "key");
  }

  get wordCount() {
    return this.state.targetWordCount;
  }

  /**
   * @returns {Object} Params needed to save taskFieldContent
   */
  get taskState() {
    const confirmedSegments = Object.values(this.state.segments).filter(
      (s) => s.isConfirmed
    );
    const confirmedBlocks = confirmedSegments.map((s) =>
      getFirstBlock(s.targetEditorState)
    );

    const content = confirmedBlocks.map((b) => b.text).join("\n");
    const rawContent = confirmedBlocks.length
      ? JSON.stringify(createRawContent(confirmedBlocks))
      : null;

    return {
      content,
      rawContent,
      taskFieldId: this.props.taskFieldId,
    };
  }

  /**
   * Returns only non-blank segments as we do not show them
   */
  getRenderableSegments = () => {
    const segments = Object.entries(this.state.segments);
    return segments.filter(([_, segment]) => !segment.isEmptyLine);
  };

  updateActiveEditorState = (editorState) => {
    const { activeEditorKey } = this.state;
    if (!activeEditorKey) return;

    this.updateEditorState(activeEditorKey, editorState);
  };
  /**
   * Update an editor state for a segment
   *
   * @param key key of the segment to update
   * @param editorState the new editor state of the segment
   */
  updateEditorState = (key, editorState) => {
    if (editorState.getLastChangeType() === "change-block-type") return;

    const { targetEditorState, ...previousSegment } = this.state.segments[key];

    const segment = {
      ...previousSegment,
      targetEditorState: editorState,
    };

    this.updateSegments(
      {
        ...this.state.segments,
        [key]: segment,
      },
      () => {
        const prevContent = targetEditorState.getCurrentContent();
        const nextContent = editorState.getCurrentContent();

        if (prevContent !== nextContent) {
          // if the content is confirmed, we need to propagate block style changes
          if (segment.isConfirmed) {
            this.props.setConfirmedSegmentBlock(
              segment.sourceText,
              getFirstBlock(editorState)
            );
            // if it's not confirmed, then store locally until confirmation
          } else {
            if (!this.lsTaskKeysToSave[key]) this.lsTaskKeysToSave[key] = true;
            this.savedToLocalStorageThrottled();
          }
        }
      }
    );
  };

  saveToLocalStorage = () => {
    Object.keys(this.lsTaskKeysToSave).forEach((key) => {
      const { sourceText, targetEditorState } = this.state.segments[key];

      const lsKey = [...this.props.localStorageKeys, sourceText];
      const firstBlock = getFirstBlock(targetEditorState);
      const { text } = firstBlock;

      if (!text || text.length === 0) {
        removeLSItemV2(lsKey);
      } else {
        setLSItemV2(lsKey, firstBlock);
      }
    });

    this.lsTaskKeysToSave = {};
  };

  onFocus = (activeEditorKey) => {
    this.setState({ activeEditorKey });
  };

  /**
   * @param {Object} positionRect position area rectangle
   * @param {string} type the type of comment (inline, field)
   * @param {number} [commentGroupId]
   * @param {Object} [selection] draft js selection
   */
  openCommentBox = (key, positionRect, type, commentGroupId, selection) => {
    this.updateSegment(key, {
      ...this.state.segments[key],
      selectedCommentGroup: {
        positionRect,
        type,
        commentGroupId,
        selection,
      },
    });
  };

  closeCommentBox = (key) => {
    this.updateSegment(key, {
      ...this.state.segments[key],
      selectedCommentGroup: null,
    });
  };

  updateSelectedCommentGroup = (key, selectedCommentGroup) => {
    this.updateSegment(key, {
      ...this.state.segments[key],
      selectedCommentGroup,
    });
  };

  onCommentAdded = () => {
    this.updateDecorators();
  };

  render() {
    const {
      deliverableId,
      fieldName,
      isCommentable,
      isEditable,
      taskFieldId,
      stageId,
      qualityChecks,
    } = this.props;
    const {
      activeEditorKey,
      sourceWordCount,
      targetWordCount,
      targetCharCount,
    } = this.state;

    const segments = this.getRenderableSegments();
    const activeEditor = this.state.segments[activeEditorKey];
    const editorState = activeEditor && activeEditor.targetEditorState;

    return (
      <div
        ref={(node) => {
          this.wrapperRef = node;
        }}
        className={`${styles.task} task task-tm`}
        id={`task-${taskFieldId}`}
      >
        <TitleBar
          deliverableId={deliverableId}
          fieldName={fieldName}
          isCommentable={isCommentable}
          stageId={stageId}
          taskFieldId={taskFieldId}
        />

        {segments.length === 0 ? (
          <div className={styles.empty}>
            There are no segments to translate for this task field
          </div>
        ) : (
          <div>
            <div className={styles.toolbarContainer}>
              <div className={styles.source} />
              <div className={styles.target}>
                <Toolbar
                  editorState={editorState}
                  setEditorState={this.updateActiveEditorState}
                  onlyInlineStyles
                />
              </div>
            </div>

            {segments.map(([key, segment]) => (
              <Segment
                key={key}
                closeCommentBox={this.closeCommentBox}
                confirmSegment={this.confirmSegment}
                deliverableId={deliverableId}
                draftKey={key}
                focusNextUnconfirmedEditor={this.focusNextUnconfirmedEditor}
                isCommentable={isCommentable}
                isConfirmed={segment.isConfirmed}
                isEditable={isEditable}
                onFocus={this.onFocus}
                openCommentBox={this.openCommentBox}
                saveConfirmedSegments={this.saveConfirmedSegments}
                stageId={stageId}
                taskFieldId={taskFieldId}
                unconfirmSegment={this.unconfirmSegment}
                updateDecorators={this.updateDecorators}
                updateEditorState={this.updateEditorState}
                updateSegmentEditorRef={this.updateSegmentEditorRef}
                updateSelectedCommentGroup={this.updateSelectedCommentGroup}
                {...segment}
              />
            ))}

            <div className={styles.wordCountContainer}>
              <div className={styles.source}>
                <Counter wordCount={sourceWordCount} />
              </div>
              <div className={styles.target}>
                <Counter
                  maxCharacters={this.props.maxCharacters}
                  targetCharCount={targetCharCount}
                  wordCount={targetWordCount}
                  {...qualityChecks}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

TaskTM.propTypes = {
  accountId: PropTypes.number.isRequired,
  addTMResponses: PropTypes.func.isRequired,
  commentGroups: PropTypes.object.isRequired,
  comments: PropTypes.array,
  confirmedSegmentBlocks: PropTypes.object.isRequired,
  createTranslation: PropTypes.func.isRequired,
  deliverableId: PropTypes.number.isRequired,
  fieldName: PropTypes.string.isRequired,
  focusNextUnconfirmedEditor: PropTypes.func,
  getConfirmedSegmentBlock: PropTypes.func.isRequired,
  isCommentable: PropTypes.bool,
  isEditable: PropTypes.bool,
  localStorageKeys: PropTypes.array.isRequired,
  onWordCountUpdate: PropTypes.func.isRequired,
  personId: PropTypes.number.isRequired,
  qualityChecks: PropTypes.shape({
    maxWords: PropTypes.number,
    minWords: PropTypes.number,
    qualityCheck: PropTypes.bool,
  }).isRequired,
  saveContent: PropTypes.func.isRequired,
  setConfirmedSegmentBlock: PropTypes.func.isRequired,
  sourceFieldRawContent: PropTypes.object,
  stageId: PropTypes.number.isRequired,
  taskFieldId: PropTypes.number.isRequired,
  taskFieldRawContent: PropTypes.object,
  translationParams: PropTypes.object.isRequired,
  translations: PropTypes.object,
  updateSegmentEditorRef: PropTypes.func,
};

export default TaskTM;
