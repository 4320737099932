import React, { Component, useMemo } from "react";
import PropTypes from "prop-types";
import Modal from "../../../../../Modal";
import Comment from "./Comment";
import styles from "./CommentGroup.module.scss";
import { COMMENT_STYLE_PREFIX } from "../../../_plugins/InlineComments";
import { MentionsInput, Mention } from "react-mentions";
import Mentions from "../../../../../Mentions/Mentions";
import { hideErrors } from "../../../../../../modules/errors";
import { maxBy } from "lodash";
import { over } from "lodash/fp";

const MAX_CHARS = 5000;
const WIDTH = 350;
const MIN_LEFT = 60;
const TRIANGLE_SIZE = 8;

let draftText = {};

export default class CommentGroup extends Component {
  state = {
    commentText: "",
    resolved: this.props.isResolved,
    mentionUsers: [],
    memoizedMentionUsers: [],
  };
  boxRef = React.createRef();
  triangleRef = React.createRef();
  commentsRef = React.createRef();
  textAreaRef = React.createRef();

  get draftText() {
    const { deliverableId } = this.props;
    return (
      draftText[deliverableId] && draftText[deliverableId][this.draftTextKey]
    );
  }

  set draftText(text) {
    const { deliverableId } = this.props;
    if (!draftText[deliverableId]) {
      draftText = { [deliverableId]: {} };
    }
    draftText[deliverableId][this.draftTextKey] = text;
  }

  get draftTextKey() {
    const { commentGroupId, commentType, taskFieldId } = this.props;
    return `${taskFieldId}-${commentGroupId || commentType}`;
  }

  componentDidMount() {
    document.addEventListener("mouseup", this.handleClickOutside);
    const { position } = this.props;
    if (position && position.left) {
      const triangleEl = this.triangleRef.current;
      if (triangleEl && triangleEl.style) {
        const leftPos = `${
          position.left - TRIANGLE_SIZE + position.width / 2
        }px`;
        triangleEl.style.left = leftPos;
        triangleEl.style.top = `${position.bottom}px`;
      }
      const box = this.boxRef.current;
      if (box && box.style) {
        const boxX = position.left - WIDTH + position.width / 2 + WIDTH / 2;
        const maxLeft =
          typeof window === "undefined"
            ? WIDTH
            : window.innerWidth - WIDTH - 10;
        box.style.top = `${position.bottom + TRIANGLE_SIZE}px`;
        box.style.left = `${Math.min(maxLeft, Math.max(MIN_LEFT, boxX))}px`;
        box.style.display = "block";
      }
    }
    if (!this.props.commentGroupId || this.props.commentType === "field") {
      this.focus();
    }
    this.setState({ commentText: this.draftText });
    this.onCommentsUpdated();
  }

  componentWillUnmount() {
    document.removeEventListener("mouseup", this.handleClickOutside);
    this.draftText = this.state.commentText;
  }

  componentDidUpdate(prevProps) {
    if (this.props.comments.length !== prevProps.comments.length) {
      this.onCommentsUpdated();
      this.focus();
    }
  }

  focus() {
    if (this.textAreaRef.current) {
      this.textAreaRef.current.focus();
    }
  }

  onCommentsUpdated() {
    const commentsEl = this.commentsRef.current;
    if (!commentsEl) return;
    if (commentsEl.scrollHeight === commentsEl.clientHeight) {
      commentsEl.style["margin-right"] = "0px";
    }
    commentsEl.scrollTop = commentsEl.scrollHeight;
  }

  handleClickOutside = (event) => {
    if (
      !this.props.modal.display &&
      !this.boxRef.current.contains(event.target)
    ) {
      this.props.onClose();
    }
  };

  createComment = async () => {
    const { userId, createComment, onCommentAdded } = this.props;
    let { commentText } = this.state;

    commentText = commentText.replace(
      /@\[(.*?)\]/g,
      '<span style="color: #007bff;font-weight: bold;">@$1</span>'
    );

    if (!commentText) return;

    let { commentGroupId } = this.props;
    if (!commentGroupId) {
      const commentGroup = await this.createCommentGroup();
      commentGroupId = commentGroup.commentGroupId;
    }
    this.draftText = "";
    const comment = await createComment(commentGroupId, userId, commentText);
    if (onCommentAdded) {
      onCommentAdded(comment);
    }
    this.setState({ commentText: "", resolved: false });
  };

  createCommentGroup = async () => {
    const {
      stageId,
      deliverableId,
      taskFieldId,
      commentType,
      selection,
      createCommentGroup,
      addInlineStyle,
    } = this.props;
    const commentGroup = await createCommentGroup({
      deliverableId,
      taskFieldId,
      atStage: stageId,
      commentType,
    });
    if (commentType === "inline") {
      addInlineStyle(
        `${COMMENT_STYLE_PREFIX}${commentGroup.commentGroupId}`,
        selection,
        "add-inline-comment"
      );
    }
    return commentGroup;
  };

  onCommentArchiveRequest = (commentId) => {
    this.requestedArchiveCommentId = commentId;
    this.props.showModal();
  };

  cancelArchiveRequest = () => {
    this.props.hideModal();
    this.setState({ requestedArchiveCommentId: null });
  };

  confirmArchiveComment = async () => {
    await this.props.archiveComment(this.requestedArchiveCommentId);
    const allArchived = this.props.comments.every((a) => a.archived);
    if (allArchived) {
      this.props.onClose();
      if (this.props.commentType === "inline") {
        this.props.removeInlineStyle(
          `${COMMENT_STYLE_PREFIX}${this.props.commentGroupId}`,
          this.props.selection
        );
      }
      if (this.props.onCommentGroupArchived) {
        this.props.onCommentGroupArchived(this.props.commentGroupId);
      }
    }
    this.props.hideModal();
  };

  resolveComment = () => {
    const { resolveCommentGroup, commentGroupId } = this.props;
    const { resolved } = this.state;
    this.setState({ resolved: !resolved });
    resolveCommentGroup({ commentGroupId, resolved: !resolved });
  };

  handleMentionsChange = (e, newValue, newPlainTextValue, mentions) => {
    this.setState({ commentText: newValue });
    this.props.updateMentionsInStore(mentions);
  };

  render() {
    const { comments, isCommentable, modal, userId, isResolved, clients } =
      this.props;

    return [
      <div key="triangle" ref={this.triangleRef} className={styles.triangle} />,

      <div key="box" ref={this.boxRef} className={styles.commentGroup}>
        {comments.length > 0 && (
          <div ref={this.commentsRef} className={styles.comments}>
            {comments.map((comment) => (
              <Comment
                key={comment.commentId}
                comment={comment}
                isResolved={isResolved}
                onCommentArchiveRequest={this.onCommentArchiveRequest}
                userId={userId}
              />
            ))}
          </div>
        )}

        {(isCommentable || comments.length > 0) && (
          <div className={styles.newComment}>
            <div className={styles.textareaBox}>
              <Mentions
                value={this.state.commentText}
                onChange={this.handleMentionsChange}
                data={clients}
              />
            </div>
            <div className={styles.resolvedContainer}>
              <label className={styles.container}>
                {isResolved ? (
                  <span className={styles.resolvedTrue}>Resolved</span>
                ) : (
                  <span className={styles.resolvedFalse}>Resolve</span>
                )}
                <input
                  checked={isResolved}
                  onChange={this.resolveComment}
                  type="checkbox"
                />
                <span className={styles.checkmark} />
              </label>
              <button className={styles.submit} onClick={this.createComment}>
                Add comment
              </button>
            </div>
          </div>
        )}

        {modal.display && (
          <Modal
            key="modal"
            body={
              <div>
                Are you sure you want to delete this comment? This cannot be
                undone.
              </div>
            }
            footer={
              <div>
                <button
                  className={styles.modalCancel}
                  onClick={this.cancelArchiveRequest}
                >
                  Cancel
                </button>
                <button
                  className={styles.modalConfirm}
                  onClick={this.confirmArchiveComment}
                >
                  Delete
                </button>
              </div>
            }
            title={"Delete this comment?"}
          />
        )}
      </div>,
    ];
  }
}

CommentGroup.propTypes = {
  addInlineStyle: PropTypes.func,
  archiveComment: PropTypes.func.isRequired,
  commentGroupId: PropTypes.number,
  commentType: PropTypes.string.isRequired,
  comments: PropTypes.array.isRequired,
  createComment: PropTypes.func.isRequired,
  createCommentGroup: PropTypes.func.isRequired,
  deliverableId: PropTypes.number.isRequired,
  hideModal: PropTypes.func.isRequired,
  isCommentable: PropTypes.bool,
  isResolved: PropTypes.bool,
  modal: PropTypes.shape({
    display: PropTypes.bool.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onCommentAdded: PropTypes.func,
  onCommentGroupArchived: PropTypes.func,
  position: PropTypes.object.isRequired,
  removeInlineStyle: PropTypes.func,
  resolveCommentGroup: PropTypes.func,
  selection: PropTypes.object,
  showModal: PropTypes.func.isRequired,
  stageId: PropTypes.number.isRequired,
  taskFieldId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  initialClientTeam: PropTypes.array.isRequired,
  updateMentionUsers: PropTypes.func.isRequired,
  updateMentionsInStore: PropTypes.func.isRequired,
  clients: PropTypes.array.isRequired,
};
