import { upsertData } from "../utils/normalize";
import { createAction } from "redux-actions";
import { postGraphQL } from "../../utils/fetch";
import { RESET_INITIAL_STATE } from "./me";
import {
  commentGroupFields,
  fetchCommentGroupsSuccess,
  updateCommentGroupsSuccess,
} from "./commentGroups";
import { Modifier } from "draft-js";

// ------------------------------------
// GraphQL Queries
// ------------------------------------
const commentFields = `archived, commentGroupId, commentId, createDate, personId, text,
  commenterFullName, deliverableId, taskFieldId, commentType, resolved`;

export const commentsByAssignmentIdQuery = `comments (assignmentId: $assignmentId, commentType: "deliverable") {
    ${commentFields}
  }`;

export const commentsByBatchIdQuery = `comments (batchId: $batchId, commentType: "batch") {
    ${commentFields}
  }`;

export const commentsByAssignmentGroupQuery = `comments (assignmentGroupId: $assignmentGroupId, commentType: "deliverable") {
    ${commentFields}
  }`;

export const commentsByDeliverableIdQuery = `comments (deliverableId: $deliverableId, commentType: "deliverable") {
    ${commentFields}
  }`;

// ------------------------------------
// Constants
// ------------------------------------
export const CREATE_COMMENT_SUCCESS = "CREATE_COMMENT_SUCCESS";
export const FETCH_COMMENTS_SUCCESS = "FETCH_COMMENTS_SUCCESS";

// ------------------------------------
// Actions
// ------------------------------------
export const createCommentSuccess = createAction(CREATE_COMMENT_SUCCESS);
export const fetchCommentsSuccess = createAction(FETCH_COMMENTS_SUCCESS);

export function createComment(
  commentGroupId,
  personId,
  text,
  commentBox,
  mentionUsers
) {
  return (dispatch) => {
    const mentionPattern = /\(id:(\w+)\)/g;
    const mentionIds = [];
    let match;

    while ((match = mentionPattern.exec(text)) !== null) {
      mentionIds.push(parseInt(match[1], 10));
    }

    const cleanedText = text.replace(/\(id:\w+\)/g, "");

    const mentionUsersArray =
      mentionIds.length > 0 ? [...new Set(mentionIds)] : null;

    const input = {
      commentGroupId,
      personId,
      text: cleanedText,
      mentionUsers: mentionUsersArray,
    };

    const query = `mutation createComment ($input: CommentInput) {
      createCommentV2 (input: $input) {
        comment { ${commentFields} }
        commentGroup { commentGroupId, resolved }
      }
    }`;

    if (cleanedText.length === 0) {
      return new Promise((resolve, reject) => {
        reject(new Error("Text cannot be empty"));
      });
    }

    return postGraphQL(query, { input }, "createCommentV2")
      .then(({ comment, commentGroup }) => {
        dispatch(createCommentSuccess(comment));
        dispatch(updateCommentGroupsSuccess(commentGroup));
        if (commentBox) {
          commentBox.value = "";
        }
        return comment;
      })
      .catch((err) => {
        console.log("createComment ERROR", err);
        return err;
      });
  };
}

export function archiveComment(commentId, editorState) {
  return (dispatch, getState) => {
    const query = `mutation archiveComment($input: CommentInput) {
      archiveComment(input: $input) {
        comment {
          ${commentFields}
        }
        commentGroup {
          ${commentGroupFields}
        }
      }
    }`;

    return postGraphQL(query, { input: { commentId } }, "archiveComment")
      .then(({ comment, commentGroup }) => {
        dispatch(fetchCommentsSuccess(comment));

        // If the comment group exists in the response, we can infer that the comment
        // group has been archived and therefore comment group styles should be removed
        if (commentGroup) {
          const contentState = editorState.getCurrentContent();
          const selection = editorState.getSelection();

          const contentStateWithComment = Modifier.removeInlineStyle(
            contentState,
            selection,
            `COMMENT-${comment.commentGroupId}`
          );

          dispatch(fetchCommentGroupsSuccess(commentGroup));
          return contentStateWithComment;
        }
      })
      .catch((err) => err);
  };
}

// ------------------------------------
// Action Handlers
// ------------------------------------
export const commentActionHandlers = {
  [RESET_INITIAL_STATE]: () => commentInitialState,
  [CREATE_COMMENT_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "commentId"),
  [FETCH_COMMENTS_SUCCESS]: (state, { payload }) =>
    upsertData(state, payload, "commentId"),
};

export const commentInitialState = { entities: {}, result: [] };
