import { postJson } from "../../utils/fetch";
import { createAction } from "redux-actions";
import { upsertData } from "../utils/normalize";
import { RESET_INITIAL_STATE } from "./me";
import Papa from "papaparse";
import { showSuccessMessage, showErrorMessage } from "./messagesV2";
import { translationSelector } from "../routes/Admin/Glossary/containers";

export const EXCLUDE_KEYS = [
  "translationId",
  "sourceSegmentId",
  "accountId",
  "archived",
  "translatedSegmentId",
];

const UPDATE_GLOSSARY_SUCCESS = "UPDATE_GLOSSARY_SUCCESS";
const REMOVE_GLOSSARY_SUCCESS = "REMOVE_GLOSSARY_SUCCESS";

export const updateGlossarySuccess = createAction(UPDATE_GLOSSARY_SUCCESS);
export const removeGlossarySuccess = createAction(REMOVE_GLOSSARY_SUCCESS);

export const mergeGlossary = (translations) =>
  translations.reduce((acc, cur) => {
    const existingIndex = acc.findIndex(
      (x) => x.sourceSegmentId === cur.sourceSegmentId
    );

    if (existingIndex !== -1) {
      const existing = acc[existingIndex];
      if (cur.translatedSegmentId > existing.translatedSegmentId) {
        acc[existingIndex] = cur;
      }
    } else {
      acc.push(cur);
    }

    return acc;
  }, []);

export const glossaryActionHandlers = {
  [RESET_INITIAL_STATE]: () => glossaryInitialState,
  [UPDATE_GLOSSARY_SUCCESS]: (state, { payload }) => {
    return upsertData(glossaryInitialState, payload, "sourceSegmentId");
  },
};

const url = () =>
  `${
    window.__REACT_APP_API_GATEWAY__ || process.env.REACT_APP_API_GATEWAY
  }/translation-memory`;

export const glossaryInitialState = { entities: {}, result: [] };

export function uploadGlossary(
  data,
  {
    match: {
      params: { accountId },
    },
  },
  sourceLanguage
) {
  return async (dispatch, getState) => {
    try {
      const isValidFile = sourceLanguage
        ? data.some((item) => item[sourceLanguage])
        : true;

      if (!isValidFile) {
        const message = `The source language uploaded is wrong; please update your file.`;
        dispatch(showErrorMessage(message));
        return;
      }

      const state = getState();

      const oldTranslations = Object.values(state.glossary.entities);

      // Find the maximum sourceSegmentId from oldTranslations
      const maxOldSourceSegmentId = Math.max(
        ...oldTranslations.map((item) => item.sourceSegmentId),
        0
      );

      const uploadedData = [...data];

      // Merging old translations with the new uploaded translations
      let mergedTranslations;
      if (sourceLanguage) {
        mergedTranslations = oldTranslations.map((translation) => {
          const uploadedTranslationIndex = uploadedData.findIndex(
            (item) => translation[sourceLanguage] === item[sourceLanguage]
          );
          if (uploadedTranslationIndex >= 0) {
            const uploadedTranslation = uploadedData[uploadedTranslationIndex];
            uploadedData.splice(uploadedTranslationIndex, 1);
            return { ...translation, ...uploadedTranslation };
          }
          return translation;
        });
      }

      // Prepare new translations with incremented sourceSegmentIds
      let newTranslations = [];
      let index = 0;
      uploadedData.forEach((item) => {
        // do not add translation if source language word doesn't exist
        if (item[sourceLanguage]) {
          newTranslations.push({
            ...item,
            accountId: Number(accountId),
            sourceSegmentId: maxOldSourceSegmentId + 1 + index,
            archived: 0, // Increment from the max
          });
          index += 1;
        }
      });

      // merge and update
      const finalTranslations = [
        ...(mergedTranslations || oldTranslations),
        ...newTranslations,
      ];

      dispatch(updateGlossarySuccess(finalTranslations));

      // Show a success message
      const message = `Import successful - don't forget to save!`;
      dispatch(showSuccessMessage(message));
    } catch (error) {
      console.error("An error occurred during glossary upload:", error);
      // Add any error handling logic here...
    }
  };
}

function chunkArray(array, chunkSize) {
  const result = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }
  return result;
}

export function updateGlossary({
  match: {
    params: { accountId },
  },
}) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const { me: personId } = state;
      const { entities } = state.glossary;

      const {
        values: { translations: updatedFormValues },
      } = state.form.GlossaryForm;
      console.log(
        "🚀 ~ file: glossary.js:119 ~ return ~ updatedFormValues:",
        updatedFormValues
      );
      const archivedFormValues = Object.values(entities).filter(
        ({ archived }) => archived
      );

      const message = `Glossary uploading`;

      dispatch(showSuccessMessage(message));

      const segmentChunks = chunkArray(
        [...updatedFormValues, ...archivedFormValues],
        300
      );

      for (let i = 0; i < segmentChunks.length; i++) {
        const chunk = segmentChunks[i];
        try {
          await postJson(`${url()}/glossary`, {
            formValues: chunk,
            accountId,
            personId,
          });
        } catch (error) {
          console.error("Failed chunk:", chunk, "Reason:", error);
        }
      }

      dispatch(showSuccessMessage("Glossary saved successfully"));
    } catch (err) {
      console.error(err); // consider dispatching an error message here
    }
  };
}

export function downloadGlossary(formName) {
  return async (_dispatch, getState) => {
    try {
      const formValues = getState().form[formName].values["translations"];
      const allKeys = [...new Set(formValues.flatMap(Object.keys))].filter(
        (key) => !EXCLUDE_KEYS.includes(key)
      );

      const completeData = formValues.map((item) => {
        const newItem = {};
        allKeys.forEach((key) => (newItem[key] = item[key] || ""));
        return newItem;
      });

      // Convert form values to CSV
      const csv = Papa.unparse(completeData);
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Glossary.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {}
  };
}

export function removeTranslation(
  index,
  {
    match: {
      params: { accountId },
    },
  }
) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const translationState = Object.values(state.glossary.entities);
      const translations = translationSelector(state, accountId);

      const { sourceSegmentId } = translations[index];

      const indexToUpdate = translationState.findIndex(
        (t) => t.sourceSegmentId === sourceSegmentId
      );

      if (indexToUpdate === -1) {
        // Handle "not found" case
        return;
      }

      translationState[indexToUpdate].archived = 1;

      dispatch(updateGlossarySuccess(translationState));
    } catch (err) {
      console.error(err); // consider dispatching an error message here
    }
  };
}
