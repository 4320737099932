import React, { memo } from "react";
import PropTypes from "prop-types";
import styles from "./TitleBar.module.scss";
import FieldComments from "../FieldComments";

const TitleBar = ({
  approvedAndModified,
  commentLength,
  deliverableId,
  fieldCommentGroupId,
  fieldName,
  isCommentable,
  stageId,
  taskFieldId,
  initialClientTeam,
}) => (
  <div className={styles.titleBar}>
    <h2 className={styles.fieldName}>
      <span>{fieldName}</span>
      {approvedAndModified ? (
        <span className={styles.modified}>(Modified after approval)</span>
      ) : (
        ""
      )}
    </h2>

    {(isCommentable || commentLength > 0) && (
      <FieldComments
        commentLength={commentLength}
        deliverableId={deliverableId}
        fieldCommentGroupId={fieldCommentGroupId}
        stageId={stageId}
        taskFieldId={taskFieldId}
        initialClientTeam={initialClientTeam}
      />
    )}
  </div>
);

TitleBar.propTypes = {
  approvedAndModified: PropTypes.bool,
  commentLength: PropTypes.number.isRequired,
  deliverableId: PropTypes.number.isRequired,
  fieldCommentGroupId: PropTypes.number,
  fieldName: PropTypes.string.isRequired,
  isCommentable: PropTypes.bool.isRequired,
  stageId: PropTypes.number.isRequired,
  taskFieldId: PropTypes.number.isRequired,
};

export default memo(TitleBar);
