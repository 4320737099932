import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./Briefing.module.scss";
import classNames from "classnames";
import Helmet from "react-helmet";
import removeHTML from "../../../../../../../../utils/removeHTML";
import BatchSelector from "../../../../../../../../components/BatchSelector";
import Attachments from "../../../../../../../../components/Attachments";
import FeatureToggle from "../../../../../../../../components/FeatureToggle";

const BRIEFING_TAB_SELECTED_BATCH_KEY = "admin/briefing/batch-id";

const Briefing = class Briefing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropDownToggle: false,
      filteredParentDeliverableBriefingFields: [],
      filteredParentDeliverables: [],
      loadingData: false,
      submitButton: null,
      activeParentDeliverable: "",
    };
  }

  scrollListener = (e) => {
    const scrollLeft = e.currentTarget.scrollLeft;
    const scrollTop = e.currentTarget.scrollTop;
    document.getElementById("table-header").scroll(scrollLeft, scrollTop);
  };

  toggleAttachmentsModal = (e, parentDeliverableId) => {
    e.stopPropagation();
    this.setState({ activeParentDeliverable: parentDeliverableId });
  };

  modifyHeaderStyles = (e, props = this.props) => {
    /*  If the table's cumulative columns will exceed the width of the window,
        then we need to apply absolute positioning on the header in order to
        allow for horizontal scrolling. In the case where this is not true,
        absolute positioning will throw the header columns out of alignment, so
        we should not apply it. */
    const NAV_WIDTH = 44;
    const COLUMN_WIDTH = 200;
    const COLUMN_WIDTH_WITH_PADDING = 220;
    const numColumns = props.tableData.headers.length + 1; // batchId is no longer included in the headers as it's a dropdown component
    const widthExceedsWindow =
      window.innerWidth - NAV_WIDTH - numColumns * COLUMN_WIDTH_WITH_PADDING <=
      0;
    const headers = Array.from(document.querySelectorAll("[data-header]"));

    if (widthExceedsWindow) {
      headers.forEach((header) => {
        header.style.width = `${COLUMN_WIDTH}px`;
      });
    } else {
      /*  When the number of columns multiplied by their width does not exceed the
          window width, we need to calculate custom widths in order to maintain
          alignment, since absolute positioning is used. */
      const COLUMN_WIDTH_WITH_PADDING_AND_BORDER = 21;
      const headerColumnWidth =
        (window.innerWidth - NAV_WIDTH) / numColumns -
        COLUMN_WIDTH_WITH_PADDING_AND_BORDER;
      headers.forEach((header, idx) => {
        header.style.width =
          idx === 0 ? `${headerColumnWidth - 1}px` : `${headerColumnWidth}px`;
      });
    }
  };

  componentDidMount() {
    const overflow = document.getElementById("overflow");
    const container = document.getElementById("container");
    // Set container height so that it stretches to the bottom of the page
    container.style.height = `calc(100vh - ${overflow.offsetTop}px)`;
    container.addEventListener("scroll", this.scrollListener);
    window.addEventListener("resize", this.modifyHeaderStyles);
    this.modifyHeaderStyles();
  }

  componentWillReceiveProps(nextProps) {
    this.modifyHeaderStyles(null, nextProps);
  }

  componentWillUnmount() {
    document
      .getElementById("container")
      .removeEventListener("scroll", this.scrollListener);
    window.removeEventListener("resize", this.modifyHeaderStyles);
  }

  updateParentDeliverablesInTable = (selectedBatchId) => {
    const filteredParentDeliverables =
      this.props.tableData.rows[selectedBatchId] || [];
    this.setState({ filteredParentDeliverables });
  };

  handleRowClick = (parentDeliverableId) => {
    this.props.history.push({
      pathname: `/admin/projects/${this.props.projectId}/parent-deliverables/${parentDeliverableId}/edit`,
    });
  };

  dropdownOnToggle = (dropDownToggle) => this.setState({ dropDownToggle });

  dropdownSelectBatch = async ({ batchId }) => {
    if (batchId) {
      this.setState({ loadingData: true });
      await this.props.fetchParentDeliverablesAndBriefingFieldsByBatchId(
        batchId
      );
      this.updateParentDeliverablesInTable(batchId);
      this.setState({ loadingData: false });
    }
  };

  render() {
    const dropdownStyles = classNames({
      [styles.dropdownActive]: this.state.dropDownToggle,
    });

    return (
      <div className={dropdownStyles}>
        <Helmet>
          <title>Project Briefing</title>
        </Helmet>
        <div className={styles.overflow} id="overflow">
          <div className={styles.container} id="container">
            <table>
              <thead className={styles.header} id="table-header">
                <tr className={styles.tr}>
                  <th>
                    <div data-header>
                      <BatchSelector
                        batches={this.props.batches}
                        dataReady={this.props.dataReady}
                        history={this.props.history}
                        localStorageKey={BRIEFING_TAB_SELECTED_BATCH_KEY}
                        location={this.props.location}
                        onToggle={this.dropdownOnToggle}
                        selectBatch={this.dropdownSelectBatch}
                      />
                    </div>
                  </th>
                  {this.props.tableData.headers.map((header, idx) => (
                    <th key={idx}>
                      <div className={styles.th} data-header>
                        {header}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {this.state.filteredParentDeliverables.map(
                  (parentDeliverableRow, idx) => (
                    <tr
                      key={idx}
                      onClick={() =>
                        this.handleRowClick(parentDeliverableRow[1])
                      }
                    >
                      {parentDeliverableRow.map((row, rowIdx) => {
                        if (row === "Attachments") {
                          return (
                            <FeatureToggle
                              key={rowIdx}
                              children={
                                <td
                                  onClick={(e) =>
                                    this.toggleAttachmentsModal(
                                      e,
                                      parentDeliverableRow[1]
                                    )
                                  }
                                >
                                  <div className={styles.firstColumn}>
                                    <button
                                      className={styles.attachmentsButton}
                                    >
                                      View Attachments
                                    </button>
                                    {this.state.activeParentDeliverable ===
                                      parentDeliverableRow[1] && (
                                      <Attachments
                                        projectId={this.props.projectId}
                                        parentDeliverableId={
                                          parentDeliverableRow[1]
                                        }
                                        toggleAttachmentsModal={
                                          this.toggleAttachmentsModal
                                        }
                                      />
                                    )}
                                  </div>
                                </td>
                              }
                              toggle={"attachmentUpload"}
                            />
                          );
                        }
                        return (
                          <td key={rowIdx}>
                            <div className={styles.column}>
                              {removeHTML(row)}
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
};

Briefing.propTypes = {
  batches: PropTypes.array,
  dataReady: PropTypes.bool.isRequired,
  fetchParentDeliverablesAndBriefingFieldsByBatchId: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  location: PropTypes.object,
  projectId: PropTypes.number.isRequired,
  tableData: PropTypes.shape({
    headers: PropTypes.array.isRequired,
    rows: PropTypes.object.isRequired,
  }).isRequired,
};

export default Briefing;
