import { getGraphQL } from "../../../../utils/fetch";
import {
  commentsByBatchIdQuery,
  fetchCommentsSuccess,
} from "../../../modules/comments";
import {
  commentGroupBatchesByBatchIdQuery,
  fetchCommentGroupsSuccess,
} from "../../../modules/commentGroups";
import {
  projectTeamsByProjectIdQuery,
  fetchProjectTeamsByProjectIdSuccess,
} from "../../../modules/projectTeams";

export function getInitialData(params, url, cookies) {
  return (dispatch, getState) => {
    const query = `query BatchFeedbackDataRequest ($batchId: String) {
      ${commentsByBatchIdQuery}
      ${commentGroupBatchesByBatchIdQuery}
    }`;

    return getGraphQL(query, params, url, cookies)
      .then((json) => {
        dispatch(fetchCommentsSuccess(json.comments));
        dispatch(fetchCommentGroupsSuccess(json.commentGroupBatches));
      })
      .catch((err) => err);
  };
}
