import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import AssignmentEditByDeliverable from "../../../../../../../../../../components/AssignmentEdit/_decorators/ByDeliverable";
import PageHeader from "../../../../../../../../../../components/PageHeader";
import Errors from "../../../../../../../../../../components/Errors";
import ProjectInfo from "../../../../../../../../../../components/PageHeader/ProjectInfo";
import BatchFeedbackButton from "../../../../../../../../../../components/AssignmentEdit/_components/BatchFeedbackButton";
import styles from "./DeliverableEdit.module.scss";

export default function DeliverableEdit({
  batch: { batchId, batchName },
  deliverableId,
  errors,
  history,
  isCommentable,
  languageCode,
  listUrl,
  nextUrl,
  previousUrl,
  project: { accountName, orderFormNumber, parentAccountName, projectName },
  projectId,
  stage: { stageId, stageName, isFinal },
}) {
  return (
    <div>
      <Helmet>
        <title>Tasks</title>
      </Helmet>

      {errors.display && <Errors />}

      <PageHeader
        breadCrumbItems={[
          { url: `/admin`, text: "Parent accounts" },
          {
            url: `/admin/projects/${projectId}/${
              isFinal ? "completed" : "with-client"
            }`,
            text: `${parentAccountName} | ${accountName} | ${orderFormNumber}`,
          },
          { url: `${listUrl}`, text: `${batchName} - ${stageName}` },
          { text: "Task" },
        ]}
        title={projectName}
      >
        <div className={styles.headerRight}>
          <ProjectInfo url={`/admin/projects/${projectId}/project-info`} />
          <BatchFeedbackButton
            batchId={batchId}
            languageCode={languageCode}
            stageId={stageId}
            projectId={projectId}
          />
        </div>
      </PageHeader>

      <AssignmentEditByDeliverable
        batchId={batchId}
        deliverableId={deliverableId}
        history={history}
        isCommentable={isCommentable}
        isCurrentStage={false}
        isEditable={false}
        listUrl={listUrl}
        nextUrl={nextUrl}
        previousUrl={previousUrl}
        projectId={projectId}
        stageId={stageId}
      />
    </div>
  );
}

DeliverableEdit.propTypes = {
  batch: PropTypes.shape({
    batchId: PropTypes.number,
    batchName: PropTypes.string,
  }),
  deliverableId: PropTypes.number.isRequired,
  errors: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  isCommentable: PropTypes.bool,
  languageCode: PropTypes.string,
  listUrl: PropTypes.string,
  nextUrl: PropTypes.string,
  previousUrl: PropTypes.string,
  project: PropTypes.shape({
    accountName: PropTypes.string,
    orderFormNumber: PropTypes.string,
    parentAccountName: PropTypes.string,
    projectId: PropTypes.number,
    projectName: PropTypes.string,
  }),
  projectId: PropTypes.number.isRequired,
  stage: PropTypes.object,
};
