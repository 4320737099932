import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { renderBriefingFieldValue } from "../../../utils/renderBriefingFieldValue";
import styles from "./VersionHistory.module.scss";

const VersionHistory = (props) => {
  const { versionHistory } = props;

  if (versionHistory.length === 0) {
    return null;
  }

  const { stages, tasks, stageContent } = Object.values(versionHistory)[0];

  // Future refactoring for client needed
  const stageNames = stages.map(({ stageName }, i) => (
    <th key={i}>{stageName}</th>
  ));

  const taskFields = tasks.map(
    ({ taskFieldName, taskFieldId, taskFieldFormat }, i) => {
      return (
        <tr key={i}>
          <th scope="row">{taskFieldName}</th>
          {stages.map(({ stageId }) => {
            const [content] = stageContent
              .filter(
                (content) =>
                  content.stageId === stageId &&
                  content.taskFieldId === taskFieldId
              )
              .map(({ content }, i) => content);

            const hasContent = content && content.length > 0;
            return (
              <td
                key={stageId}
                className={classNames({
                  [styles.italic]: !hasContent,
                  [styles.contentStyle]: true,
                })}
              >
                {hasContent
                  ? renderBriefingFieldValue(content, taskFieldFormat)
                  : "No changes"}
              </td>
            );
          })}
        </tr>
      );
    }
  );

  return (
    <table>
      <tbody>
        <tr>
          <th />
          {stageNames}
        </tr>
        {taskFields}
      </tbody>
    </table>
  );
};

VersionHistory.propTypes = {
  versionHistory: PropTypes.array.isRequired,
};

export default VersionHistory;
