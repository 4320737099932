import React, { useRef } from "react";
import PropTypes from "prop-types";
import styles from "./LoginButton.module.scss";
import { useAuth0 } from "@auth0/auth0-react";
import { Authsignal } from "@authsignal/browser";

function LoginButton({ auth0Login, disabled }) {
  const authsignal = useRef(
    new Authsignal({
      tenantId:
        window.__REACT_APP_AUTHSIGNAL_TENANT_ID__ ||
        process.env.REACT_APP_AUTHSIGNAL_TENANT_ID,
      baseUrl:
        window.__REACT_APP_AUTHSIGNAL_BASE_URL__ ||
        process.env.REACT_APP_AUTHSIGNAL_BASE_URL,
    })
  );

  const { loginWithRedirect } = useAuth0();

  return (
    <button
      onClick={() =>
        loginWithRedirect({
          authorizationParams: { device_id: authsignal.current?.anonymousId },
        })
      }
      className={styles.loginButton}
      disabled={disabled}
    >
      Log In
    </button>
  );
}

LoginButton.propTypes = {
  auth0Login: PropTypes.bool,
};

export default LoginButton;
