import { isChrome } from "./dom";

const isChromeBrowser = isChrome();

// positive look behind for punctuation/spaces/word boundary/start of string
export const wordBoundaryStart = isChromeBrowser
  ? "(?<=([-_.,!\"']|\\b|\\s|^))"
  : "(\\b|\\s|^)";
// positive look forward for punctuation/spaces/word boundary/end of string
export const wordBoundaryEnd = isChromeBrowser
  ? "(?=([-_.,!\"']|\\b|\\s|$))"
  : "(\\b|\\s|$)";

/**
 * Sanitise a word for a regex matcher
 *
 * https://stackoverflow.com/a/2021210/5165902
 * some chars need to be escaped:
 * \-\.\/\[\]\\ **always** need escaping
 * \*\+\?\)\{\}\| need escaping when **not** in a character class- [a-z*+{}()?]
 *
 * @param {string} word
 * @returns {string}
 */
export function sanitiseWord(word) {
  return word.replace(/[-[/\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

/**
 * Creates regex and matches all words which are passed in
 *
 * @param {string[]} words passed into regex
 * @param {string} options regex options
 * @returns {RegExp} returns regular expression
 */
export function wordMatchRegex(wordsUnescaped, languageCode, options = "ig") {
  const words = wordsUnescaped.map(sanitiseWord);

  const noBoundaryLanguages = ["zh-Hans", "zh-Hant", "ja-JP", "ko-KR"];

  const regexPattern = noBoundaryLanguages.includes(languageCode)
    ? `(${words.join("|")})`
    : `${wordBoundaryStart}(${words.join("|")})${wordBoundaryEnd}`;

  return new RegExp(regexPattern, options);
}
