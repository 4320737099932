import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import OverlayLoading from "../../components/OverlayLoading/v2";
import QualityGradingModal from "../../components/QualityGradingModal";
import Transitions from "../Transitions";
import styles from "./AssignmentEdit.module.scss";
import PanelToggle from "./SidePanel/components/PanelToggle";
import SidePanel from "./SidePanel";
import Tasks from "./Tasks";
import Modal from "../../components/Modal";
import { ModalType } from "../../modules/modal";

const SaveStatus = Object.freeze({
  Default: 1,
  Saving: 2,
  Success: 3,
  Error: 4,
});

/**
 * An editor for all tasks in an assignment
 */
class AssignmentEdit extends Component {
  constructor(props) {
    super(props);

    this.tasksComponent = null;

    this.state = {
      editOverridden: false,
      saveStatus: SaveStatus.Default,
      showSidePanel: true,
      totalWordCount: 0,
      grammarCheckLoading: false,
    };
  }

  get isEditable() {
    const { isEditable, plagiarismChecksInProgress, isAiStage } = this.props;

    // ongoing plagiarism checks should disable editing for all fields
    if (
      plagiarismChecksInProgress ||
      this.state.grammarCheckLoading ||
      isAiStage
    )
      return false;

    return isEditable || this.state.editOverridden;
  }

  componentWillUnmount() {
    this.clearSaveBtnResetTimer();
  }
  /**
   * Override isEditable
   */
  enableEditOverride = () => {
    this.setState({ editOverridden: true });
  };

  disableEditOverride = () => {
    this.setState({ editOverridden: false });
  };

  startSaveBtnResetTimer = () => {
    this.resetTimer = setTimeout(() => {
      this.setState({ saveStatus: SaveStatus.Default });
    }, 3000);
  };

  clearSaveBtnResetTimer = () => {
    clearTimeout(this.resetTimer);
  };

  saveAll = () => {
    this.tasksComponent && this.tasksComponent.saveAll();
    this.disableEditOverride();
  };

  discardAll = (oldEditorState) => {
    this.tasksComponent && this.tasksComponent.discardAll();
  };

  /**
   * This function is responsible for handling saving TFC and providing updates to UI
   *
   * @param {Array<Object>} rows array of task field content entries to save
   */
  saveContentHandler = async (rows) => {
    try {
      this.clearSaveBtnResetTimer();
      this.setState({ saveStatus: SaveStatus.Saving });
      await this.props.saveContent(rows);
      this.setState({ saveStatus: SaveStatus.Success });

      this.startSaveBtnResetTimer();
    } catch (err) {
      this.setState({ saveStatus: SaveStatus.Error });
      throw err;
    }
  };

  /**
   * This function tries to save all task fields and then process the transition
   */
  processTransitionHandler = async (transition) => {
    const {
      assignmentId,
      checkForUnconfirmedTMSegments,
      processTransition,
      showWarningMessage,
      shouldShowTM,
    } = this.props;

    if (shouldShowTM) {
      const data = await checkForUnconfirmedTMSegments([assignmentId]);

      if (data.status === "error" && data.type === "UNCONFIRMED_SEGMENTS") {
        return showWarningMessage("Please confirm all unconfirmed segments");
      }
    }

    try {
      // we want to process every task regardless of whether it has been changed (to keep a visual trail of when things transitioned)
      const rows = this.tasksComponent.getAllRows();
      // make sure the function throws an error to stop transitions happening
      await this.saveContentHandler(rows);

      // only after we know the content has saved we should process the transition
      await processTransition(transition);
    } catch (e) {} // error already handled but need a catch block
  };

  toggleSidePanel = () => {
    this.setState({ showSidePanel: !this.state.showSidePanel });
  };

  /**
   * Updates the total word count for all tasks
   */
  updateTotalWordCount = (totalWordCount) => {
    this.setState({ totalWordCount });
  };

  discardChanges = async () => {
    await this.props.discardClientContent();
    this.tasksComponent.discardChanges();
    this.disableEditOverride();
    this.props.hideModal();
  };

  renderSaveButton = () => {
    if (!this.isEditable) {
      return this.props.allowEditOverride || this.props.allowEditInReview ? (
        <button className={styles.editButton} onClick={this.enableEditOverride}>
          Edit
        </button>
      ) : null;
    }

    const { saveStatus } = this.state;

    const success = saveStatus === SaveStatus.Success;
    const error = saveStatus === SaveStatus.Error;
    const saving = saveStatus === SaveStatus.Saving;

    const btnStyles = classNames({
      [styles.saveButton]: true,
      [styles.error]: error,
      [styles.success]: success,
      [styles.saving]: saving,
    });

    let btnContent = "Save";

    if (success) {
      btnContent = (
        <svg
          height="25"
          viewBox="0 0 16 16"
          width="30"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M13.5 2L6 9.5 2.5 6 0 8.5l6 6 10-10z" />
        </svg>
      );
    } else if (error) {
      btnContent = "!";
    } else if (saving) {
      btnContent = (
        <img
          alt="Loading..."
          className={styles.rotate}
          src="/icons/nav/sidebar-scribe-logo.png"
          width="30"
        />
      );
    }

    return (
      <div className={styles.saveBtnContainer}>
        <button className={btnStyles} onClick={this.saveAll}>
          {btnContent}
        </button>
      </div>
    );
  };

  /**
   * Renders an editor for each task in the assignment
   */
  render() {
    const { showSidePanel, saveStatus } = this.state;
    const saving = saveStatus === SaveStatus.Saving;

    const {
      addToDictionary,
      allowClientChanges,
      briefingFieldInfo,
      bannedWords,
      deliverableId,
      isDeliverableApproved,
      grammarChecks,
      isBulkQA,
      isCommentable,
      isCurrentStage,
      isLocalisationProject,
      keywords,
      personId,
      personType,
      projectId,
      stageId,
      tasks,
      transitions,
      rateBandId,
      shouldShowTM,
      languageCode,
      sourceLanguage,
      taskNavigationComponent,
      wasModifiedAfterApproval,
      wasModifiedByClient,
      isWithClient,
      parentDeliverableId,
      removeSuggestion,
      processTransitionGrammarCheck,
      allowGrammarCheck,
      glossary_v2,
      canRegenerateAIContent,
      glossaryWords,
      glossarySourceWords,
      initialClientTeam,
    } = this.props;

    return (
      <div>
        <div className={styles.mainContainer}>
          <div className={styles.tasksContainer}>
            {!isBulkQA && (
              <PanelToggle
                isPanelOpen={showSidePanel}
                onClick={this.toggleSidePanel}
              />
            )}

            {!isBulkQA && (
              <div className={styles.navBar}>
                {taskNavigationComponent}
                {wasModifiedAfterApproval && (
                  <span className={styles.modified}>
                    Modified after approval
                  </span>
                )}
                {wasModifiedByClient && allowClientChanges && (
                  <span className={styles.modified}>
                    Modified during client review
                  </span>
                )}
              </div>
            )}

            {isLocalisationProject && (
              <div className={styles.localisationContainer}>
                <div className={styles.text}>Source ({sourceLanguage})</div>
                <div className={styles.text}>
                  Target {languageCode ? `(${languageCode})` : ""}
                </div>
              </div>
            )}
            {!this.props.dataReady ? (
              <OverlayLoading />
            ) : (
              <Tasks
                ref={(ref) => {
                  this.tasksComponent = ref;
                }}
                bannedWords={bannedWords}
                deliverableId={deliverableId}
                isCommentable={isCommentable}
                isCurrentStage={isCurrentStage}
                isEditable={this.isEditable}
                isLocalisationProject={isLocalisationProject}
                keywords={keywords}
                onWordCountUpdate={this.updateTotalWordCount}
                personId={personId}
                projectId={projectId}
                saveContent={this.saveContentHandler}
                shouldShowTM={shouldShowTM}
                stageId={stageId}
                rateBandId={rateBandId}
                tasks={tasks}
                grammarChecks={grammarChecks}
                removeSuggestion={removeSuggestion}
                processTransitionGrammarCheck={processTransitionGrammarCheck}
                addToDictionary={addToDictionary}
                allowGrammarCheck={allowGrammarCheck}
                languageCode={languageCode}
                glossaryWords={glossaryWords}
                glossarySourceWords={glossarySourceWords}
                initialClientTeam={initialClientTeam}
              />
            )}
          </div>

          {showSidePanel && (
            <SidePanel
              bannedWords={bannedWords}
              briefingFieldInfo={briefingFieldInfo}
              deliverableId={deliverableId}
              isDeliverableApproved={isDeliverableApproved}
              isWithClient={isWithClient}
              keywords={keywords}
              personType={personType}
              projectId={projectId}
              shouldShowTM={shouldShowTM}
              stageId={stageId}
              tasks={tasks}
              transitions={transitions}
              parentDeliverableId={parentDeliverableId}
              glossary_v2={glossary_v2}
              languageCode={languageCode}
            />
          )}
        </div>

        <div className={styles.infoBar}>
          <div className={styles.opacityBackground} />

          <div className={styles.totalWordCount}>
            Total word count: {this.state.totalWordCount}
          </div>

          <Transitions
            containerStyles={styles.transitionContainer}
            processTransition={this.processTransitionHandler}
            transitions={transitions}
            isWithClient={isWithClient}
            wasModifiedByClient={wasModifiedByClient && allowClientChanges}
            canRegenerateAIContent={canRegenerateAIContent}
            altButtonStyle
          >
            <>
              {this.renderSaveButton()}
              {this.props.allowEditInReview && (
                <button
                  name="Discard"
                  className={styles.discardActive}
                  disabled={saving || !wasModifiedByClient}
                  onClick={this.props.showModal}
                >
                  Discard changes
                </button>
              )}

              <Modal
                body={
                  <div>
                    Are you sure you would like to discard all changes? (This
                    will remove any comments left by Client)
                  </div>
                }
                footer={
                  <div>
                    <button
                      className={styles.modalCancel}
                      onClick={this.props.hideModal}
                    >
                      Cancel
                    </button>
                    <button
                      className={styles.modalConfirm}
                      disabled={saving || !wasModifiedByClient}
                      onClick={this.discardChanges}
                    >
                      Discard changes
                    </button>
                  </div>
                }
                type={ModalType.DiscardChanges}
              />
            </>
          </Transitions>
        </div>

        <QualityGradingModal reviewerStageId={stageId} />
      </div>
    );
  }
}

AssignmentEdit.propTypes = {
  allowEditOverride: PropTypes.bool,
  assignmentId: PropTypes.number.isRequired,
  bannedWords: PropTypes.array,
  briefingFieldInfo: PropTypes.array,
  checkForUnconfirmedTMSegments: PropTypes.func.isRequired,
  dataReady: PropTypes.bool,
  deliverableId: PropTypes.number,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  isCommentable: PropTypes.bool.isRequired,
  isCurrentStage: PropTypes.bool.isRequired,
  isDeliverableApproved: PropTypes.bool,
  isEditable: PropTypes.bool.isRequired,
  isLocalisationProject: PropTypes.bool.isRequired,
  isWithClient: PropTypes.bool.isRequired,
  keywords: PropTypes.array,
  languageCode: PropTypes.string,
  messages: PropTypes.shape({
    display: PropTypes.bool,
  }),
  personId: PropTypes.number.isRequired,
  personType: PropTypes.string.isRequired,
  processTransition: PropTypes.func.isRequired,
  projectId: PropTypes.number.isRequired,
  saveContent: PropTypes.func.isRequired,
  shouldShowTM: PropTypes.bool,
  showWarningMessage: PropTypes.func.isRequired,
  sourceLanguage: PropTypes.string,
  stageId: PropTypes.number,
  taskNavigationComponent: PropTypes.object,
  tasks: PropTypes.arrayOf(PropTypes.object).isRequired,
  transitions: PropTypes.array,
  wasModifiedAfterApproval: PropTypes.bool,
};

export default AssignmentEdit;
